import React, { useState, useEffect } from "react";
import {
  Table,
  Modal,
  Avatar,
  Button,
  Row,
  Col,
  DatePicker,
  Form,
  Input,
  Select,
  Upload,
  message,
  Progress,
} from "antd";
import { UploadOutlined, MessageOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import DropOption from "../components/DropOption";
import ModalForm from "../components/ModalForm";
import { useDispatch, useSelector } from "react-redux";
import { postTickets, putTickets } from "../store/actions";
import { v4 as uuidv4 } from "uuid";
import { auth, db, storage } from "../firebase";
const { confirm } = Modal;
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const Tickets = () => {
  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
  };
  const columns = [
    {
      title: "Playlist Name",
      dataIndex: "playlistName",
      key: "playlistName",
      width: "10%",
    },
    {
      title: "Album Art",
      dataIndex: "albumart",
      key: "albumart",
      width: "10%",
      render: (text) => <Avatar style={{ marginLeft: 8 }} src={text} />,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "20%",
    },
    {
      title: "Songs",
      dataIndex: "songs",
      key: "songs",
      width: "10%",
      render: (text) => <p>{text.length}</p>,
    },

    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: "8%",
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: "1", name: `Update` },
              { key: "2", name: `Delete` },
            ]}
          />
        );
      },
    },
  ];

  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const ticketData = useSelector((state) => state.getTickets);
  // const [message, setMessage] = useState("");
  const [uploadImg, setUploadImg] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [songs, setSongs] = useState([]);
  const [users, setUsers] = useState([]);
  const [progress, setProgress] = useState();
  const [showProgress, setShowProgress] = useState(false);
  const [albumArt, setAlbumArt] = useState();
  const [uid, setUid] = useState();
  const [form] = Form.useForm();
  const { TextArea } = Input;

  console.log(songs);
  const fetchMusic = async () => {
    let newData = [];
    try {
      db.collection("music")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            itemData.tracks.forEach((item) => {
              // const track = item.data();
              newData.push(item);
            });
          });
        });
      db.collection("SFX")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            itemData.sounds.forEach((item) => {
              newData.push(item);
            });
          });
        });
      setSongs(newData);
      console.log(newData, "new data");
    } catch (err) {
      console.error(err);
    }
  };
  const fetchUsers = async () => {
    let newData = [];
    try {
      db.collection("users")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push(itemData);
          });
        });
      setUsers(newData);
      console.log(newData, "new data");
    } catch (err) {
      console.error(err);
    }
  };

  const handleMenuClick = (record, e) => {
    if (e.key === "1") {
      setShowProgress(false);
      setAlbumArt(null);
      setProgress(0);
      setEditModalVisible(true);
      console.log(record.type);
      console.log(record.user);
      form.setFieldsValue({
        id: record.id,
        playlistName: record.playlistName,
        albumart: record.albumart,
        songs: record.songs,
        description: record.description,
        type: record.type,
        user: record.user,
        public: record.public,
        followers: record.followers,
      });
    } else if (e.key === "2") {
      confirm({
        title: `Are you sure close this ticket?`,
        onOk() {
          db.collection("playlists").doc(record.id).delete();
          storage.refFromURL(record.albumart).delete();
          fetchPlaylists();
          message.success("Successfully delete!");
        },
      });
    }
  };

  const fetchPlaylists = async () => {
    let newData = [];
    try {
      db.collection("playlists")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push({
              id: doc.id,
              playlistName: itemData.playlistName,
              albumart: itemData.albumart,
              songs: itemData.songs,
              description: itemData.description,
              type: itemData.type,
              user: itemData.user,
              public: itemData.public,
              followers: itemData.followers,
            });
          });
          setData(newData);
          console.log(newData, "new data");
        });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchPlaylists();
    fetchMusic();
    fetchUsers();
  }, []);

  const handleAddProduct = async (value) => {
    setLoading(true);
    console.log(value);
    const id = uuidv4();
    db.collection("playlists")
      .doc(id)
      .set({
        playlistName: value.playlistName,
        songs: value.songs,
        description: value.description,
        user: value.user,
        type: value.type,
        public: value.public,
        followers: value.followers,
      })
      .then(async (doc) => {
        if (albumArt) {
          setShowProgress(true);
          const extension = albumArt.name.split(".");
          const resp = await storage.ref(`Playlist/${id}/${value.playlistName}.${extension[extension.length - 1]}`);
          const url = await resp.put(albumArt).then(() => resp.getDownloadURL());
          await db
            .collection("playlists")
            .doc(id)
            .update({
              albumart: url,
            })
            .then(() => {
              setProgress(100);
            });
        }
        setIsModalVisible(false);
        message.success("playlist created!");
        setLoading(false);
        fetchPlaylists();
      })
      .catch(function (error) {
        setIsModalVisible(false);
        message.error("playlist not created!");
        setLoading(false);
        console.log(error);
      });
  };
  const handleEditProduct = async (value) => {
    setLoading(true);
    console.log(value);
    db.collection("playlists")
      .doc(value.id)
      .update({
        playlistName: value.playlistName,
        songs: value.songs,
        description: value.description,
        user: value.user,
        type: value.type,
        public: value.public,
        followers: value.followers,
      })
      .then(async (doc) => {
        if (albumArt) {
          setShowProgress(true);
          storage.refFromURL(value.albumart).delete();
          const extension = albumArt.name.split(".");
          const resp = await storage.ref(
            `Playlist/${value.id}/${value.playlistName}.${extension[extension.length - 1]}`
          );
          const url = await resp.put(albumArt).then(() => resp.getDownloadURL());
          await db
            .collection("playlists")
            .doc(value.id)
            .update({
              albumart: url,
            })
            .then(() => {
              setProgress(100);
            });
        }
        setEditModalVisible(false);
        message.success("playlist updated!");
        setLoading(false);
        fetchPlaylists();
      })
      .catch(function (error) {
        setEditModalVisible(false);
        message.error("playlist not updated!");
        setLoading(false);
        console.log(error);
      });
  };
  return (
    <div className="App">
      <ModalForm isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}>
        <Form name="control-ref" layout="horizontal" onFinish={handleAddProduct}>
          <Form.Item
            name="playlistName"
            rules={[{ required: true }]}
            label={`PlayList Name`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="albumart"
            rules={[{ required: albumArt ? false : true }]}
            label={`Album Art`}
            hasFeedback
            {...formItemLayout}
          >
            <input type="file" onChange={(e) => setAlbumArt(e.target.files[0])} />
            {showProgress ? <Progress percent={progress} showInfo={false} /> : ""}
          </Form.Item>
          <Form.Item name="user" rules={[{ required: true }]} label={`Select User`} hasFeedback {...formItemLayout}>
            <Select allowClear style={{ width: "100%" }} placeholder="Select an Option">
              {users.map((item) => (
                <Option value={item.adminId}>
                  {item.firstname} {item.lastName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="type" rules={[{ required: true }]} label={`Select Type`} hasFeedback {...formItemLayout}>
            <Select allowClear style={{ width: "100%" }} placeholder="Select an Option">
              <Option value="normal">Normal</Option>
              <Option value="favorite">Favorite</Option>
            </Select>
          </Form.Item>
          <Form.Item name="public" rules={[{ required: true }]} label={`Public?`} hasFeedback {...formItemLayout}>
            <Select allowClear style={{ width: "100%" }} placeholder="Select an Option">
              <Option value={1}>true</Option>
              <Option value={0}>false</Option>
            </Select>
          </Form.Item>
          <Form.Item name="followers" rules={[{ required: true }]} label={`Followers`} hasFeedback {...formItemLayout}>
            <Select mode="multiple" optionLabelProp="label" style={{ width: "100%" }} placeholder="Select an Option">
              {users.map((item) => (
                <Option value={item.adminId} label={`${item.firstname}`}>
                  {item.firstname} {item.lastName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="songs" rules={[{ required: true }]} label={`Songs`} hasFeedback {...formItemLayout}>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select an Option"
              // defaultValue={["china"]}
              // onChange={handleChange}
              optionLabelProp="label"
            >
              {songs.map((item) => (
                <Option
                  value={item.trackID ? item.trackID : item.soundID}
                  label={item.trackName ? item.trackName : item.soundName}
                >
                  <div className="demo-option-label-item">{item.trackName ? item.trackName : item.soundName}</div>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="description"
            rules={[{ required: true }]}
            label={`Description`}
            hasFeedback
            {...formItemLayout}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Row justify="center">
            <Button type="primary" htmlType="submit" loading={loading} style={{ width: "80%" }}>
              Add PlayList
            </Button>
          </Row>
        </Form>
      </ModalForm>
      <ModalForm isModalVisible={editModalVisible} setIsModalVisible={setEditModalVisible}>
        <Form name="edit-fomr" layout="horizontal" form={form} onFinish={handleEditProduct}>
          <Form.Item
            name="playlistName"
            rules={[{ required: true }]}
            label={`PlayList Name`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ display: "none" }}
            name="id"
            rules={[{ required: true }]}
            label={`ID`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="albumart"
            rules={[{ required: albumArt ? false : true }]}
            label={`Album Art`}
            hasFeedback
            {...formItemLayout}
          >
            <input type="file" onChange={(e) => setAlbumArt(e.target.files[0])} />
            {showProgress ? <Progress percent={progress} showInfo={false} /> : ""}
          </Form.Item>
          <Form.Item name="user" rules={[{ required: true }]} label={`Select User`} hasFeedback {...formItemLayout}>
            <Select allowClear style={{ width: "100%" }} placeholder="Select an Option">
              {users.map((item) => (
                <Option value={item.adminId}>
                  {item.firstname} {item.lastName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="type" rules={[{ required: true }]} label={`Select Type`} hasFeedback {...formItemLayout}>
            <Select allowClear style={{ width: "100%" }} placeholder="Select an Option">
              <Option value="normal">Normal</Option>
              <Option value="favorite">Favorite</Option>
            </Select>
          </Form.Item>
          <Form.Item name="public" rules={[{ required: true }]} label={`Public?`} hasFeedback {...formItemLayout}>
            <Select allowClear style={{ width: "100%" }} placeholder="Select an Option">
              <Option value={1}>true</Option>
              <Option value={0}>false</Option>
            </Select>
          </Form.Item>
          <Form.Item name="followers" rules={[{ required: true }]} label={`Followers`} hasFeedback {...formItemLayout}>
            <Select mode="multiple" optionLabelProp="label" style={{ width: "100%" }} placeholder="Select an Option">
              {users.map((item) => (
                <Option value={item.adminId} label={`${item.firstname}`}>
                  {item.firstname} {item.lastName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="songs" rules={[{ required: true }]} label={`Songs`} hasFeedback {...formItemLayout}>
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
              // defaultValue={["a10", "c12"]}
              optionLabelProp="label"
            >
              {songs.map((item) => (
                <Option
                  value={item.trackID ? item.trackID : item.soundID}
                  label={item.trackName ? item.trackName : item.soundName}
                >
                  <div className="demo-option-label-item">{item.trackName ? item.trackName : item.soundName}</div>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="description"
            rules={[{ required: true }]}
            label={`Description`}
            hasFeedback
            {...formItemLayout}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Row justify="center">
            <Button type="primary" htmlType="submit" loading={loading} style={{ width: "80%" }}>
              Edit PlayList
            </Button>
          </Row>
        </Form>
      </ModalForm>

      <Form name="control-ref">
        <Row justify="space-between">
          <Col span={8}>
            <Form.Item name="name">
              <Search
                placeholder={`Search Name`}
                // onSearch={this.handleSubmit}
              />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Button
              type="ghost"
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              Create
            </Button>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Table
          // {...tableProps}
          pagination
          dataSource={data}
          // className={styles.table}
          bordered
          scroll={{ x: 800 }}
          columns={columns}
          simple
          rowKey={(record) => record.id}
        />
      </div>
    </div>
  );
};

export default Tickets;
