import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import { Form, Row, Col, Input, Button, Upload, Progress } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { auth, db, storage } from "../firebase";

const SoundsForm = forwardRef((props, ref) => {
  const [soundId, setSoundId] = useState(props.sound.soundID);
  const [soundName, setSoundName] = useState(props.sound.soundName);
  const [mood1, setMood1] = useState(props.sound.mood1);
  const [mood2, setMood2] = useState(props.sound.mood2);
  const [mp3audio, setMP3audio] = useState(null);
  const [mp3AudioUrl, setMP3audioUrl] = useState(props.sound.mp3AudioUrl ? props.sound.mp3AudioUrl : "");
  const [mp3audioHQ, setMP3audioHQ] = useState(null);
  const [mp3AudioUrlHQ, setMP3audioUrlHQ] = useState(props.sound.wavAudioUrl ? props.sound.wavAudioUrl : "");
  const [loading, setLoading] = useState(false);
  const [progressVisible1, setProgressVisible1] = useState(false);
  const [progressVisible2, setProgressVisible2] = useState(false);
  const [progress1, setProgress1] = useState(0);
  const [progress2, setProgress2] = useState(0);
  // const progress1 = React.useRef(0);
  // const progress2 = React.useRef(0);

  const [showProgress, setShowProgress] = useState(false);

  useImperativeHandle(ref, () => ({
    async handleSubmit(artist) {
      try {
        props.setLoading(true);
        setLoading(true);
        setProgressVisible2(true);
        const url2 = mp3audio ? await uploadFileToFirestore(mp3audio, props.sound.mp3AudioUrl, soundName, artist) : "";
        console.log(url2);
        if (url2 !== "") {
          setProgress2(100);
        }
        setProgressVisible1(true);
        const url = mp3audioHQ
          ? await uploadFileToFirestore(mp3audioHQ, props.sound.wavAudioUrl, soundName, artist)
          : "";
        console.log(url);
        if (url !== "") {
          setProgress1(100);
        }

        setMP3audioUrl(url);
        setMP3audioUrlHQ(url2);
        await props.update({
          soundID: soundId,
          soundName: soundName,
          mood1: mood1,
          mood2: mood2,
          mp3AudioUrl: url ? url : mp3AudioUrl,
          wavAudioUrl: url2 ? url2 : mp3AudioUrlHQ,
          trackPlays: 0,
          downloads: 0,
          averageDurationPlayed: 0,
        });
        props.setLoading(false);
        setLoading(false);
      } catch (error) {
        alert(`Error: ${error}`);
        props.setLoading(false);
        setLoading(false);
      }
    },
  }));

  const uploadFileToFirestore = (image, oldImage, soundName, artist) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(image.name);
        const extension = image.name.split(".");
        const deleteFile = oldImage ? await storage.refFromURL(oldImage).delete() : "";
        var storageRef = await storage.ref(
          `SFX/${props.packName}/${soundId}/${artist}_${soundName}.${extension[extension.length - 1]}`
        );

        //Upload file
        var task = storageRef.put(image);
        console.log(task);
        //Update progress bar
        task.on(
          "state_changed",
          (snapshot) => {
            var percentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            console.log(percentage);
            // console.log(progress1.current);
          },
          function error(err) {},
          async function complete() {
            const downloadURL = await storageRef.getDownloadURL();
            return resolve(downloadURL);
          }
        );
      } catch (error) {
        return reject(error);
      }
    });
  };
  return (
    <div style={{ borderTop: "1px solid #484848", padding: "1.5em 0" }}>
      <h2
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span>Sound {props.index}</span>{" "}
        <CloseCircleOutlined
          onClick={() => {
            storage.refFromURL(mp3AudioUrl).delete();
            storage.refFromURL(mp3AudioUrlHQ).delete();
            props.deleteSound(soundId);
          }}
        />
      </h2>
      <Row gutter={24}>
        <Col span={6}>
          {" "}
          <label htmlFor="">Sound Name</label>
          <Form.Item name={`sound_${props.index}`} rules={[{ required: true }]} hasFeedback>
            <Input value={soundName} onChange={(e) => setSoundName(e.target.value)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          {" "}
          <label htmlFor="">Mood 1</label>
          <Form.Item name={`sound_${props.index}_mood1`} rules={[{ required: true }]} hasFeedback>
            <Input value={mood1} onChange={(e) => setMood1(e.target.value)} />
          </Form.Item>
        </Col>
        <Col span={6}>
          {" "}
          <label htmlFor="">Mood 2</label>
          <Form.Item name={`sound_${props.index}_mood2`} rules={[{ required: true }]} hasFeedback>
            <Input value={mood2} onChange={(e) => setMood2(e.target.value)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6} style={{ marginBottom: "24px" }}>
          <label htmlFor="">Select HQ Audio</label>
          <Form.Item
            name={`sound_${props.index}_MP3audioHQ`}
            rules={[{ required: mp3audioHQ || mp3AudioUrlHQ ? false : true }]}
            hasFeedback
          >
            <input type="file" accept=".wav" onChange={(e) => setMP3audioHQ(e.target.files[0])} />
            {progressVisible1 ? <Progress percent={progress1} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
        <Col span={6} style={{ marginBottom: "24px" }}>
          <label htmlFor="">Select MP3 Audio</label>

          <Form.Item
            name={`sound_${props.index}_MP3audio`}
            rules={[{ required: mp3audio || mp3AudioUrl ? false : true }]}
            hasFeedback
          >
            <input type="file" accept=".mp3" onChange={(e) => setMP3audio(e.target.files[0])} />
            {progressVisible2 ? <Progress percent={progress2} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
});
export default SoundsForm;
