import React, { useState } from "react";
import { Layout, Menu, message } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  DollarOutlined,
  InboxOutlined,
  CustomerServiceOutlined,
  FileProtectOutlined,
  ShopOutlined,
  SolutionOutlined,
  TransactionOutlined,
  TagOutlined,
  VerifiedOutlined,
} from "@ant-design/icons";
import "../App.css";
import Products from "./Products";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import SFX from "./SFX";
import CreateSound from "./CreateSound";
import UpdateSound from "./UpdateSound";
import LUT from "./LUT";
import CreateLut from "./CreateLut";
import UpdateLut from "./UpdateLut";
import Users from "./Users";
import Music from "./Music";
import CreateMusic from "./CreateMusic";
import UpdateMusic from "./UpdateMusic";
import Playlists from "./Playlists";
import Transactions from "./Transactions";
import { useDispatch } from "react-redux";
import Messages from "./Messages";
import ServiceSubscriptions from "./ServiceSubscriptions";
import StreamPlaylist from "./StreamPlaylist";
import { useAuth } from "../contexts/AuthContext";

const { Header, Sider, Content } = Layout;
const LayoutSide = () => {
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const { currentUser, logout } = useAuth();
  async function handleLogout() {
    try {
      await logout();
      history.push("/");
    } catch {
      message.error("Failed to log out");
    }
  }
  return (
    <Layout style={{ height: "100vh" }}>
      <Sider
        width={200}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          setCollapsed(broken);
        }}
        // onCollapse={(collapsed, type) => {
        //   console.log(collapsed, type);
        // }}
        trigger={null}
        // collapsible
        collapsed={collapsed}
      >
        <div className="logo">
          <p className="txtLogo">CJams</p>
        </div>
        <Menu theme="light" mode="inline" defaultSelectedKeys={["0"]}>
          <Menu.Item key="0" icon={<UserOutlined />}>
            <Link to="/dashboard"> Users</Link>
          </Menu.Item>
          <Menu.Item key="1" icon={<InboxOutlined />}>
            <Link to="/dashboard/plans"> Plans</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<CustomerServiceOutlined />}>
            <Link to="/dashboard/sfx"> SFX </Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<FileProtectOutlined />}>
            <Link to="/dashboard/lut"> LUT </Link>
          </Menu.Item>
          <Menu.Item key="5" icon={<SolutionOutlined />}>
            <Link to="/dashboard/music"> Music </Link>
          </Menu.Item>
          <Menu.Item key="6" icon={<SolutionOutlined />}>
            <Link to="/dashboard/playlists"> PlayLists </Link>
          </Menu.Item>
          <Menu.Item key="7" icon={<SolutionOutlined />}>
            <Link to="/dashboard/streamplaylist"> Stream PlayLists </Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            paddingLeft: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: "trigger",
            onClick: () => (collapsed ? setCollapsed(false) : setCollapsed(true)),
          })}
          {collapsed ? (
            <p className="txtLogo" style={{ marginLeft: 20 }}>
              Logo
            </p>
          ) : null}
          <h4 style={{ cursor: "pointer" }} onClick={handleLogout}>
            logout
          </h4>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            height: "100vh",
            overflowY: "auto",
          }}
        >
          <Switch>
            <Route exact path="/dashboard" component={Users} />
            <Route path="/dashboard/plans" component={Products} />
            <Route exact path="/dashboard/sfx" component={SFX} />
            <Route path="/dashboard/sounds/createSound" component={CreateSound} />
            <Route path="/dashboard/sounds/updateSound" component={UpdateSound} />
            <Route exact path="/dashboard/lut" component={LUT} />
            <Route path="/dashboard/lut/create" component={CreateLut} />
            <Route path="/dashboard/lut/update" component={UpdateLut} />
            <Route exact path="/dashboard/music" component={Music} />
            <Route path="/dashboard/music/create" component={CreateMusic} />
            <Route path="/dashboard/music/update" component={UpdateMusic} />
            <Route exact path="/dashboard/playlists" component={Playlists} />
            <Route path="/dashboard/tickets/messages" component={Messages} />
            <Route path="/dashboard/transactions" component={Transactions} />
            <Route path="/dashboard/serviceSubscriptions" component={ServiceSubscriptions} />
            <Route path="/dashboard/streamplaylist" component={StreamPlaylist} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutSide;
