import React, { useState } from "react";
import {
  Table,
  Modal,
  Row,
  Col,
  DatePicker,
  Form,
  Input,
  Select,
  Upload,
  message,
} from "antd";
import { useSelector } from "react-redux";
const { confirm } = Modal;
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const ServiceSubscriptions = () => {
  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
  };
  const columns = [
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "name",
      width: "10%",
    },
    {
      title: "Project Description",
      dataIndex: "projectDescription",
      key: "name",
      width: "20%",
    },
    {
      title: "Amount Paid",
      dataIndex: "amountPaid",
      key: "name",
      width: "10%",
    },
  ];
  const ServiceSubscriptionsData = useSelector(
    (state) => state.getServiceSubscriptions
  );

  console.log("sadadsadadsadassadada",ServiceSubscriptionsData)

  return (
    <div className="App">
      <Form name="control-ref">
        <Row justify="space-between">
          <Col span={8}>
            <Form.Item name="name">
              <Search
                placeholder={`Search Name`}
                // onSearch={this.handleSubmit}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Table
          // {...tableProps}
          pagination
          dataSource={ServiceSubscriptionsData}
          // className={styles.table}
          
          bordered
          scroll={{ x: 800 }}
          columns={columns}
          simple
          rowKey={(record) => record.id}
        />
      </div>
     
    </div>

  );
};

export default ServiceSubscriptions;
