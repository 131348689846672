import React, { useState, useRef } from "react";
import { Button, Row, Input, Form, message } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";

import styles from "../styles/Login.module.css";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { postAdmin } from "../store/actions";
import { useAuth } from "../contexts/AuthContext";

const Login = () => {
  const history = useHistory();
  const FormItem = Form.Item;
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const handleLogin = async (value) => {
    console.log(value);
    try {
      setLoading(true);
      await login(value.email, value.password);
      history.push("/dashboard");
    } catch {
      message.error("Failed to log in");
    }

    setLoading(false);
  };

  return (
    <div>
      <div className={styles.form}>
        <div className={styles.logo}>
          <p>LOGO</p>
        </div>
        <Form onFinish={handleLogin}>
          <FormItem
            name="email"
            rules={[{ required: true }, { type: "email" }]}
            hasFeedback
          >
            <Input placeholder={`Email`} />
          </FormItem>
          <FormItem name="password" rules={[{ required: true }]} hasFeedback>
            <Input type="password" placeholder={`Password`} />
          </FormItem>
          <Row style={{ justifyContent: "center" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Sign in
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default Login;
