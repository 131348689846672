import React, { useState } from "react";
import {
  Table,
  Modal,
  Row,
  Col,
  DatePicker,
  Form,
  Input,
  Select,
  Upload,
  message,
} from "antd";
import { useSelector } from "react-redux";
const { confirm } = Modal;
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const Transactions = () => {
  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
  };
  const columns = [
    {
        title: "Full Name",
        dataIndex: ["checkoutBy", "fullName"],
        key: "name",
        width: "10%",
      },

      {
        title: "Email",
        dataIndex: ["checkoutBy", "email"],
        key: "name",
        width: "10%",
      },
      {
        title: "Role",
        dataIndex: ["checkoutBy", "role"],
        key: "name",
        width: "10%",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "name",
        width: "10%",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "name",
        width: "20%",
      },
      {
        title: "Card Number",
        dataIndex:"cardNumber",
        key: "name",
        width: "10%",
      },
  ];

  const transactionData = useSelector((state) => state.getTransactions);

  return (
    <div className="App">
      <Form name="control-ref">
        <Row justify="space-between">
          <Col span={8}>
            <Form.Item name="name">
              <Search
                placeholder={`Search Name`}
                // onSearch={this.handleSubmit}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Table
          // {...tableProps}
          pagination
          dataSource={transactionData}
          // className={styles.table}
          bordered
          scroll={{ x: 800 }}
          columns={columns}
          simple
          rowKey={(record) => record.id}
        />
      </div>
    </div>
  );
};

export default Transactions;
