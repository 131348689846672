import React, { useState, useEffect } from "react";
import { Table, Modal, Avatar, Button, Row, Col, DatePicker, Form, Input, Select, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import DropOption from "../components/DropOption";
import ModalForm from "../components/ModalForm";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { auth, db, storage } from "../firebase";
const { confirm } = Modal;
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const Music = () => {
  const history = useHistory();
  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
  };
  const columns = [
    {
      title: "Album Name",
      dataIndex: "albumName",
      key: "albumName",
      width: "10%",
    },
    {
      title: "Album Image",
      dataIndex: "albumImage",
      key: "albumImage",
      width: "10%",
      render: (text) => <Avatar style={{ marginLeft: 8 }} src={text} />,
    },
    {
      title: "Number of Songs",
      dataIndex: "numberTracks",
      key: "numberTracks",
      width: "10%",
    },
    {
      title: "Artist Name",
      dataIndex: "artistName",
      key: "artistName",
      width: "20%",
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: "8%",
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: "1", name: `Update` },
              { key: "2", name: `Delete` },
            ]}
          />
        );
      },
    },
  ];
  const [data, setData] = useState();

  const handleMenuClick = (record, e) => {
    if (e.key === "1") {
      history.push({ pathname: "/dashboard/music/update", state: record });
    } else if (e.key === "2") {
      confirm({
        title: `Are you sure delete this record?`,
        onOk() {
          db.collection("music").doc(record.id).delete();
          storage.refFromURL(record.albumImage).delete();
          if (record.tracks) {
            for (let i = 0; i < record.tracks.length; i++) {
              storage.refFromURL(record.tracks[i].fullmixmp3).delete();
              storage.refFromURL(record.tracks[i].fullmixwav).delete();
              storage.refFromURL(record.tracks[i].drumsmp3).delete();
              storage.refFromURL(record.tracks[i].drumswav).delete();
              storage.refFromURL(record.tracks[i].bassmp3).delete();
              storage.refFromURL(record.tracks[i].basswav).delete();
              storage.refFromURL(record.tracks[i].instmp3).delete();
              storage.refFromURL(record.tracks[i].instwav).delete();
              storage.refFromURL(record.tracks[i].vocalsmp3).delete();
              storage.refFromURL(record.tracks[i].vocalswav).delete();
            }
          }
          fetchMusic();
          message.success("Successfully delete!");
        },
      });
    }
  };

  const fetchMusic = async () => {
    let newData = [];
    try {
      db.collection("music")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push({
              id: doc.id,
              albumName: itemData.albumName,
              artistName: itemData.artistName,
              albumImage: itemData.albumImage,
              numberTracks: itemData.numberTracks,
              tracks: itemData.tracks,
            });
          });
          setData(newData);
          console.log(newData, "new data");
        });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchMusic();
  }, []);

  return (
    <div className="App">
      <Form name="control-ref">
        <Row justify="space-between">
          <Col span={8}>
            <Form.Item name="name">
              <Search
                placeholder={`Search Name`}
                // onSearch={this.handleSubmit}
              />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Button type="ghost" onClick={() => history.push("/dashboard/music/create")}>
              Create
            </Button>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Table
          // {...tableProps}
          pagination
          dataSource={data}
          // className={styles.table}
          bordered
          scroll={{ x: 800 }}
          columns={columns}
          simple
          rowKey={(record) => record.id}
        />
      </div>
    </div>
  );
};

export default Music;
