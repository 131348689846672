import React, { useState, useEffect } from "react";
import {
  Table,
  Modal,
  Avatar,
  Button,
  Row,
  Col,
  DatePicker,
  Form,
  Input,
  Select,
  Upload,
  message,
  Progress,
} from "antd";
import { UploadOutlined, MessageOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import DropOption from "../components/DropOption";
import ModalForm from "../components/ModalForm";
import { useDispatch, useSelector } from "react-redux";
import { postTickets, putTickets } from "../store/actions";
import { v4 as uuidv4 } from "uuid";
import { auth, db, storage } from "../firebase";
const { confirm } = Modal;
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const Tickets = () => {
  const [playlistImage, setPlaylistImage] = useState();

  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
  };
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "10%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "10%",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "SpotifyURL",
      dataIndex: "spotifyURL",
      key: "spotifyURL",
      width: "10%",
    },
    {
      title: "AmazonURL",
      dataIndex: "amazonURL",
      key: "amazonURL",
      width: "10%",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "YoutubeURL",
      dataIndex: "youtubeURL",
      key: "youtubeURL",
      width: "10%",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "AppleMusicURL",
      dataIndex: "applemusicURL",
      key: "applemusicURL",
      width: "10%",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "DownloadURL",
      dataIndex: "downloadURL",
      key: "downloadURL",
      width: "10%",
      render: (text) => <p>{text}</p>,
    },

    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: "8%",
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: "1", name: `Update` },
              { key: "2", name: `Delete` },
            ]}
          />
        );
      },
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [songs, setSongs] = useState([]);
  const [users, setUsers] = useState([]);
  const [progress, setProgress] = useState();
  const [showProgress, setShowProgress] = useState(false);
  const [albumArt, setAlbumArt] = useState();
  const [selectedRow, setSelectedRow] = useState(false);
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const handleMenuClick = (record, e) => {
    if (e.key === "1") {
      setShowProgress(false);
      setAlbumArt(null);
      setProgress(0);
      setEditModalVisible(true);
      setSelectedRow(record);
      form.setFieldsValue({
        id: record.id,
        title: record.title,
        description: record.description,
        spotifyURL: record.spotifyURL,
        amazonURL: record.amazonURL,
        youtubeURL: record.youtubeURL,
        downloadURL: record.downloadURL,
        applemusicURL: record.applemusicURL,
      });
    } else if (e.key === "2") {
      confirm({
        title: `Are you sure close this ticket?`,
        onOk() {
          db.collection("streamPlaylist").doc(record.id).delete();
          fetchStreamPlaylist();
          message.success("Successfully delete!");
        },
      });
    }
  };

  const fetchStreamPlaylist = async () => {
    let newData = [];
    try {
      db.collection("streamPlaylist")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push({
              id: doc.id,
              title: itemData.title,
              description: itemData.description,
              spotifyURL: itemData.spotifyURL,
              amazonURL: itemData.amazonURL,
              youtubeURL: itemData.youtubeURL,
              downloadURL: itemData.downloadURL,
              applemusicURL: itemData.applemusicURL,
              iamge: itemData.image,
            });
          });
          setData(newData);
          console.log(newData, "new data");
        });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchStreamPlaylist();
  }, []);

  const uploadFileToFirestore = (image) =>
    new Promise(async (resolve, reject) => {
      try {
        setShowProgress(true);
        const extension = image.name.split(".");
        const resp = await storage.ref(
          `streamplaylists/${extension[0]}.${extension[extension.length - 1]}`
        );
        const url = await resp.put(image).then(() => resp.getDownloadURL());
        return resolve(url);
      } catch (error) {
        return reject(error);
      }
    });
  const handleAddProduct = async (value) => {
    setLoading(true);
    const id = uuidv4();

    const url = playlistImage ? await uploadFileToFirestore(playlistImage) : "";

    setPlaylistImage(false);
    db.collection("streamPlaylist")
      .doc(id)
      .set({
        title: value.title,
        description: value.description,
        spotifyURL: value.spotifyURL,
        amazonURL: value.amazonURL,
        youtubeURL: value.youtubeURL,
        downloadURL: value.downloadURL,
        applemusicURL: value.applemusicURL,
        image: url,
      })
      .then(async (doc) => {
        setIsModalVisible(false);
        message.success("playlist created!");
        setLoading(false);
        fetchStreamPlaylist();
      })
      .catch(function (error) {
        setIsModalVisible(false);
        message.error("playlist not created!");
        setLoading(false);
        console.log(error);
      });
  };
  const handleEditProduct = async (value) => {
    setLoading(true);
    const url = playlistImage
      ? await uploadFileToFirestore(playlistImage)
      : selectedRow.image;
    setPlaylistImage(false);

    db.collection("streamPlaylist")
      .doc(value.id)
      .update({
        title: value.title,
        description: value.description,
        spotifyURL: value.spotifyURL,
        amazonURL: value.amazonURL,
        youtubeURL: value.youtubeURL,
        downloadURL: value.downloadURL,
        applemusicURL: value.applemusicURL,
        image: url,
      })
      .then(async (doc) => {
        setEditModalVisible(false);
        message.success("playlist updated!");
        setLoading(false);
        fetchStreamPlaylist();
      })
      .catch(function (error) {
        setEditModalVisible(false);
        message.error("playlist not updated!");
        setLoading(false);
        console.log(error);
      });
  };
  return (
    <div className="App">
      <ModalForm
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      >
        <Form
          name="control-ref"
          layout="horizontal"
          onFinish={handleAddProduct}
        >
          <Form.Item
            name="title"
            rules={[{ required: true }]}
            label={`Title`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="spotifyURL"
            rules={[{ required: true }]}
            label={`Spotify URL`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="amazonURL"
            rules={[{ required: true }]}
            label={`Amazon URL`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="youtubeURL"
            rules={[{ required: true }]}
            label={`Youtube URL`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="downloadURL"
            rules={[{ required: true }]}
            label={`Download URL`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="applemusicURL"
            rules={[{ required: true }]}
            label={`Apple Music URL`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            rules={[{ required: true }]}
            label={`Description`}
            hasFeedback
            {...formItemLayout}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item name="albumFile" hasFeedback label="Playlist Art">
            <input
              type="file"
              onChange={(e) => setPlaylistImage(e.target.files[0])}
            />
            {showProgress ? (
              <Progress percent={progress} showInfo={false} />
            ) : (
              ""
            )}
          </Form.Item>

          <Row justify="center">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "80%" }}
            >
              Add PlayList
            </Button>
          </Row>
        </Form>
      </ModalForm>
      <ModalForm
        isModalVisible={editModalVisible}
        setIsModalVisible={setEditModalVisible}
      >
        <Form
          name="edit-fomr"
          layout="horizontal"
          form={form}
          onFinish={handleEditProduct}
        >
          <Form.Item
            name="title"
            rules={[{ required: true }]}
            label={`Title`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="spotifyURL"
            rules={[{ required: true }]}
            label={`Spotify URL`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="amazonURL"
            rules={[{ required: true }]}
            label={`Amazon URL`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="youtubeURL"
            rules={[{ required: true }]}
            label={`Youtube URL`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="downloadURL"
            rules={[{ required: true }]}
            label={`Download URL`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="applemusicURL"
            rules={[{ required: true }]}
            label={`Apple Music URL`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ display: "none" }}
            name="id"
            rules={[{ required: true }]}
            label={`ID`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            rules={[{ required: true }]}
            label={`Description`}
            hasFeedback
            {...formItemLayout}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item name="albumFile" hasFeedback label={"Playlist Art"}>
            <input
              type="file"
              onChange={(e) => setPlaylistImage(e.target.files[0])}
            />
            {showProgress ? (
              <Progress percent={progress} showInfo={false} />
            ) : (
              ""
            )}
          </Form.Item>

          <Row justify="center">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "80%" }}
            >
              Edit PlayList
            </Button>
          </Row>
        </Form>
      </ModalForm>

      <Form name="control-ref">
        <Row justify="space-between">
          <Col span={8}>
            <Form.Item name="name">
              <Search
                placeholder={`Search Name`}
                // onSearch={this.handleSubmit}
              />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Button
              type="ghost"
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              Create
            </Button>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Table
          // {...tableProps}
          pagination
          dataSource={data}
          // className={styles.table}
          bordered
          scroll={{ x: 800 }}
          columns={columns}
          simple
          rowKey={(record) => record.id}
        />
      </div>
    </div>
  );
};

export default Tickets;
