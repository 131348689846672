import React, { useState, useEffect } from "react";
import { Form, Row, Col, Input, Button, Upload, message, Progress } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import MusicForm from "../components/MusicForm";
import { v4 as uuidv4 } from "uuid";
import { auth, db, storage } from "../firebase";
import { useHistory } from "react-router-dom";

export default function UpdateMusic(props) {
  // const [tracks, setTracks] = useState(props.location.state.tracks);
  const [childRef, setChildRef] = useState([{ ref: React.createRef() }]);
  const [id, setId] = useState(props.location.state.id);
  const [albumfile, setAlbumFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState();
  const [showProgress, setShowProgress] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const tracks = React.useRef([]);

  const handleSubmit = async (value) => {
    for (let i = 0; i < childRef.length; i++) {
      const item = childRef[i];
      if (item.ref.current) {
        await item.ref.current.handleSubmit(value.artistName);
      }
    }
    console.log(value);
    db.collection("music")
      .doc(value.id)
      .update(
        {
          albumName: value.albumName,
          artistName: value.artistName,
          numberTracks: value.numberTracks,
          tracks: tracks.current,
        },
        { merge: true }
      )
      .then(async () => {
        if (albumfile) {
          try {
            const url = await uploadFileToFirestore(
              albumfile,
              props.location.state.albumImage,
              value.albumName,
              value.artistName
            );
            console.log(url);
            db.collection("music")
              .doc(id)
              .update({
                albumImage: url,
              })
              .then(() => {
                message.success("Album updated!");
                history.push("/dashboard/music");
              });
          } catch (error) {
            alert(`Error: ${error}`);
          }
        } else {
          message.success("Album updated!");
          history.push("/dashboard/music");
        }
        setLoading(false);
      })
      .catch(function (error) {
        message.error("Album not updated!");
      });
  };
  const uploadFileToFirestore = (image, oldImage, album, artist) =>
    new Promise(async (resolve, reject) => {
      try {
        setShowProgress(true);
        const extension = image.name.split(".");
        const deleteFile = oldImage ? await storage.refFromURL(oldImage).delete() : "";
        const resp = await storage.ref(`songs/${id}/${artist}_${album}.${extension[extension.length - 1]}`);
        const url = await resp.put(image).then(() => resp.getDownloadURL());
        return resolve(url);
      } catch (error) {
        return reject(error);
      }
    });
  useEffect(() => {
    form.setFieldsValue({
      numberTracks: tracks.current.length,
    });
  }, [tracks.current]);

  useEffect(() => {
    const record = props.location.state;
    const musicData = {};
    for (let i = 0; i < record.tracks.length; i++) {
      musicData[`trackName_${i + 1}`] = record.tracks[i].trackName;
      musicData[`trackName_${i + 1}_genre`] = record.tracks[i].genre;
      musicData[`trackName_${i + 1}_instrument`] = record.tracks[i].instrument;
      musicData[`trackName_${i + 1}_mood1`] = record.tracks[i].mood1;
      musicData[`trackName_${i + 1}_mood2`] = record.tracks[i].mood2;
      musicData[`trackName_${i + 1}_bpm`] = record.tracks[i].bpm;
      musicData[`trackName_${i + 1}_key`] = record.tracks[i].key;
      musicData[`trackName_${i + 1}_vocals`] = record.tracks[i].vocals;
    }

    const newFieldsValues = {
      id: record.id,
      albumName: record.albumName,
      artistName: record.artistName,
      numberTracks: record.numberTracks,
      ...musicData,
    };
    form.setFieldsValue(newFieldsValues);
    for (let i = 0; i < props.location.state.tracks.length; i++) {
      setChildRef([...childRef, { ref: React.createRef() }]);
    }
    // setChildRef(childRef.slice());
    console.log(childRef);
    tracks.current = props.location.state.tracks;
  }, []);
  return (
    <Form form={form} name="control-ref" layout="horizontal" onFinish={handleSubmit}>
      <h2>Album Uploader</h2>
      <Row gutter={24}>
        <Col span={6}>
          {" "}
          <label htmlFor="">Album Name</label>
          <Form.Item name="albumName" rules={[{ required: true }]} hasFeedback>
            <Input />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="id" rules={[{ required: true }]} hasFeedback>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label htmlFor="">Artist Name</label>
          <Form.Item name="artistName" rules={[{ required: true }]} hasFeedback>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          {" "}
          <label htmlFor="">Select Album Art</label>
          <Form.Item
            name="albumFile"
            rules={[{ required: albumfile || props.location.state.albumImage ? false : true }]}
            hasFeedback
          >
            <input type="file" onChange={(e) => setAlbumFile(e.target.files[0])} />
            {showProgress ? <Progress percent={progress} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          {" "}
          <label htmlFor="">Number of Songs</label>
          <Form.Item name="numberTracks" rules={[{ required: true }]} hasFeedback>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Button
        type="primary"
        style={{ marginBottom: "1.5em" }}
        onClick={() => {
          // setLoading(true);
          setChildRef([...childRef, { ref: React.createRef() }]);
          tracks.current = [...tracks.current, { trackID: uuidv4() }];
        }}
      >
        Add Track
      </Button>
      {tracks.current.map((track, index) => (
        <MusicForm
          key={index}
          // ref={childRef}
          ref={childRef[index].ref}
          albumName={id}
          deleteSound={(id) => {
            tracks.current = tracks.current.filter((item) => item.trackID !== id);
            setChildRef(childRef.slice());
          }}
          update={(track) => {
            tracks.current = tracks.current.map((item, ind) => {
              if (index == ind) return track;
              else return item;
            });
          }}
          track={track}
          index={index + 1}
          setLoading={(item) => setLoading(item)}
        />
      ))}
      <Row justify="center">
        <Button type="primary" loading={loading} htmlType="submit" style={{ width: "40%" }}>
          Done
        </Button>
      </Row>
    </Form>
  );
}
