import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Input, Button, Upload, message, Progress } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import SoundsForm from "../components/SoundsForm";
import { v4 as uuidv4 } from "uuid";
import { auth, db, storage } from "../firebase";
import { useHistory } from "react-router-dom";

export default function CreateSound() {
  // const [sounds, setSounds] = useState([]);
  const [childRef, setChildRef] = useState([]);
  const [packImage, setPackImage] = useState(null);
  const [id, setId] = useState(uuidv4());
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const [progress, setProgress] = useState();
  const [showProgress, setShowProgress] = useState(false);
  const sounds = React.useRef([]);

  console.log(sounds.current);
  console.log(childRef);
  const handleSubmit = async (value) => {
    for (let i = 0; i < childRef.length; i++) {
      const item = childRef[i];
      await item.ref.current.handleSubmit(value.artistName);
    }
    console.log(sounds.current);
    db.collection("SFX")
      .doc(id)
      .set(
        {
          packName: value.packName,
          artistName: value.artistName,
          category: value.category,
          numberSounds: value.numberSounds,
          sounds: sounds.current,
        },
        { merge: true }
      )
      .then(async () => {
        // Start Loading
        setLoading(true);
        try {
          const url = packImage ? await uploadFileToFirestore(packImage, value.artistName, value.packName) : "";
          db.collection("SFX")
            .doc(id)
            .update({
              packImage: url,
            })
            .then(() => {
              message.success("Pack created!");
              history.push("/dashboard/sfx");
            });
        } catch (error) {
          alert(`Error: ${error}`);
          setLoading(false);
        }
        setLoading(false);

        // Stop Loading
      })
      .catch(function (error) {
        message.error("Pack not created!");
      });
  };

  const uploadFileToFirestore = (image, artist, pack) =>
    new Promise(async (resolve, reject) => {
      try {
        setShowProgress(true);
        const extension = image.name.split(".");
        var storageRef = storage.ref(`SFX/${id}/${artist}_${pack}.${extension[extension.length - 1]}`);

        //Upload file
        var task = storageRef.put(image);

        //Update progress bar
        task.on(
          "state_changed",
          function progress(snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(percentage);
          },
          function error(err) {},
          async function complete() {
            const downloadURL = await storageRef.getDownloadURL();
            // console.log("file " + (i + 1) + " Uplaoded");
            console.log(downloadURL);
            return resolve(downloadURL);
          }
        );
      } catch (error) {
        return reject(error);
      }
    });

  useEffect(() => {
    form.setFieldsValue({
      numberSounds: sounds.current.length,
    });
  }, [sounds.current]);

  return (
    <Form form={form} name="control-ref" layout="horizontal" onFinish={handleSubmit}>
      <h2>SFX Pack Uploader</h2>
      <Row gutter={24}>
        <Col span={6}>
          {" "}
          <label htmlFor="">Pack Name</label>
          <Form.Item name="packName" rules={[{ required: true }]} hasFeedback>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          {" "}
          <label htmlFor="">Artist Name</label>
          <Form.Item name="artistName" rules={[{ required: true }]} hasFeedback>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <label htmlFor="">Category</label>
          <Form.Item name="category" rules={[{ required: true }]} hasFeedback>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6} style={{ marginBottom: "24px" }}>
          {" "}
          <label htmlFor="">Select Pack Image</label>
          <Form.Item name="packImage" rules={[{ required: packImage ? false : true }]} hasFeedback>
            <input type="file" accept=".jpg" onChange={(e) => setPackImage(e.target.files[0])} />
            {showProgress ? <Progress percent={progress} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          {" "}
          <label htmlFor="">Number of Sounds</label>
          <Form.Item name="numberSounds" rules={[{ required: true }]} hasFeedback>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Button
        type="primary"
        onClick={() => {
          // setLoading(true);
          setChildRef([...childRef, { ref: React.createRef() }]);
          sounds.current = [...sounds.current, { soundID: uuidv4() }];
        }}
        style={{ marginBottom: "1.5em" }}
      >
        Add Sound
      </Button>
      {sounds.current.map((sound, index) => (
        <SoundsForm
          key={index}
          // ref={childRef}
          ref={childRef[index].ref}
          packName={id}
          deleteSound={(id) => {
            sounds.current = sounds.current.filter((item) => item.soundID !== id);
            setChildRef(childRef.slice());
          }}
          update={(snd) => {
            sounds.current = sounds.current.map((item, ind) => {
              if (index == ind) return snd;
              else return item;
            });
          }}
          sound={sound}
          index={index + 1}
          setLoading={(item) => setLoading(item)}
        />
      ))}
      <Row justify="center">
        <Button
          type="primary"
          loading={loading}
          htmlType="submit"
          // onClick={handleOnClick}
          style={{ width: "40%" }}
        >
          Done
        </Button>
      </Row>
    </Form>
  );
}
