import React, { useEffect, useState, useRef } from "react";
import { Form, Row, Col, Input, Button, Upload, message, Progress } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import SoundsForm from "../components/SoundsForm";
import { v4 as uuidv4 } from "uuid";
import { auth, db, storage } from "../firebase";
import { useHistory } from "react-router-dom";

export default function UpdateSound(props) {
  const [form] = Form.useForm();
  const history = useHistory();
  // const [sounds, setSounds] = useState(props.location.state.sounds.current);
  const [childRef, setChildRef] = useState([{ ref: React.createRef() }]);
  const [id, setId] = useState(props.location.state.id);
  const [packImage, setPackImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState();
  const [showProgress, setShowProgress] = useState(false);
  const sounds = React.useRef([]);

  const handleSubmit = async (value) => {
    for (let i = 0; i < childRef.length; i++) {
      const item = childRef[i];
      if (item.ref.current) {
        await item.ref.current.handleSubmit(value.artistName);
      }
    }
    db.collection("SFX")
      .doc(value.id)
      .update(
        {
          packName: value.packName,
          artistName: value.artistName,
          category: value.category,
          numberSounds: value.numberSounds,
          sounds: sounds.current,
        },
        { merge: true }
      )
      .then(async (doc) => {
        setLoading(true);
        if (packImage) {
          try {
            const url = await uploadFileToFirestore(
              packImage,
              props.location.state.packImage,
              value.artistName,
              value.packName
            );
            console.log(url);
            db.collection("SFX")
              .doc(id)
              .update({
                packImage: url,
              })
              .then(() => {
                message.success("Pack Updated!");
                history.push("/dashboard/sfx");
              });
          } catch (error) {
            alert(`Error: ${error}`);
          }
        } else {
          message.success("Pack Updated!");
          history.push("/dashboard/sfx");
        }
        setLoading(false);

        // Stop Loading
      })
      .catch(function (error) {
        message.error("Pack not Updated!");
      });
  };
  const uploadFileToFirestore = (image, oldImage, artist, pack) =>
    new Promise(async (resolve, reject) => {
      try {
        setShowProgress(true);
        const deleteFile = oldImage ? await storage.refFromURL(oldImage).delete() : "";
        const extension = image.name.split(".");
        var storageRef = storage.ref(`SFX/${id}/${artist}_${pack}.${extension[extension.length - 1]}`);

        //Upload file
        var task = storageRef.put(image);

        //Update progress bar
        task.on(
          "state_changed",
          function progress(snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(percentage);
          },
          function error(err) {},
          async function complete() {
            const downloadURL = await storageRef.getDownloadURL();
            // console.log("file " + (i + 1) + " Uplaoded");
            console.log(downloadURL);
            return resolve(downloadURL);
          }
        );
      } catch (error) {
        return reject(error);
      }
    });
  useEffect(() => {
    form.setFieldsValue({
      numberSounds: sounds.current.length,
    });
  }, [sounds.current]);
  useEffect(() => {
    const record = props.location.state;
    const soundsData = {};
    for (let i = 0; i < record.sounds.length; i++) {
      soundsData[`sound_${i + 1}`] = record.sounds[i].soundName;
      soundsData[`sound_${i + 1}_mood1`] = record.sounds[i].mood1;
      soundsData[`sound_${i + 1}_mood2`] = record.sounds[i].mood2;
    }

    const newFieldsValues = {
      id: record.id,
      packName: record.packName,
      artistName: record.artistName,
      category: record.category,
      numberSounds: record.numberSounds,
      ...soundsData,
    };

    console.log(newFieldsValues);

    form.setFieldsValue(newFieldsValues);
    for (let i = 0; i < props.location.state.sounds.length; i++) {
      setChildRef([...childRef, { ref: React.createRef() }]);
    }
    console.log(childRef);
    sounds.current = props.location.state.sounds;
  }, []);
  return (
    <div>
      {" "}
      <Form form={form} name="control-ref" layout="horizontal" onFinish={handleSubmit}>
        <h2>SFX Pack Uploader</h2>
        <Row gutter={24}>
          <Col span={6}>
            {" "}
            <label htmlFor="">Pack Name</label>
            <Form.Item name="packName" rules={[{ required: true }]} hasFeedback>
              <Input />
            </Form.Item>
            <Form.Item style={{ display: "none" }} name="id" rules={[{ required: true }]} hasFeedback>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            {" "}
            <label htmlFor="">Artist Name</label>
            <Form.Item name="artistName" rules={[{ required: true }]} hasFeedback>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <label htmlFor="">Category</label>
            <Form.Item name="category" rules={[{ required: true }]} hasFeedback>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6} style={{ marginBottom: "24px" }}>
            {" "}
            <label htmlFor="">Select Pack Image</label>
            <input type="file" accept=".jpg" onChange={(e) => setPackImage(e.target.files[0])} />
            {showProgress ? <Progress percent={progress} showInfo={false} /> : ""}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            {" "}
            <label htmlFor="">Number of Sounds</label>
            <Form.Item name="numberSounds" rules={[{ required: true }]} hasFeedback>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Button
          type="primary"
          onClick={() => {
            setChildRef([...childRef, { ref: React.createRef() }]);
            sounds.current = [...sounds.current, { soundID: uuidv4() }];
          }}
          style={{ marginBottom: "1.5em" }}
        >
          Add Sound
        </Button>
        {sounds.current.map((sound, index) => (
          <SoundsForm
            key={index}
            ref={childRef[index].ref}
            packName={id}
            deleteSound={(id) => {
              sounds.current = sounds.current.filter((item) => item.soundID !== id);
              setChildRef(childRef.slice());
            }}
            update={(snd) => {
              sounds.current = sounds.current.map((item, ind) => {
                if (index == ind) return snd;
                else return item;
              });
            }}
            sound={sound}
            index={index + 1}
            setLoading={(item) => setLoading(item)}
          />
        ))}
        <Row justify="center">
          <Button loading={loading} type="primary" htmlType="submit" style={{ width: "40%" }}>
            Done
          </Button>
        </Row>
      </Form>
    </div>
  );
}
