import React, { useState, useEffect } from "react";
import { Table, Modal, Avatar, Button, Row, Col, DatePicker, Form, Input, Select, Upload, message } from "antd";
import DropOption from "../components/DropOption";
import { useHistory, Redirect } from "react-router-dom";
import { auth, db, storage } from "../firebase";
const { confirm } = Modal;
const { Search } = Input;
const SFX = () => {
  const [data, setData] = useState();
  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
  };
  const columns = [
    {
      title: "Pack Name",
      dataIndex: "packName",
      key: "packName",
      width: "10%",
    },
    {
      title: "Pack Image",
      dataIndex: "packImage",
      key: "packImage",
      width: "10%",
      render: (text) => <Avatar style={{ marginLeft: 8 }} src={text} />,
    },
    {
      title: "Artist Name",
      dataIndex: "artistName",
      key: "artistName",
      width: "20%",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: "10%",
    },

    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: "8%",
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: "1", name: `Update` },
              { key: "2", name: `Delete` },
            ]}
          />
        );
      },
    },
  ];
  const history = useHistory();

  const fetchSounds = async () => {
    let newData = [];
    try {
      db.collection("SFX")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push({
              id: doc.id,
              packName: itemData.packName,
              packImage: itemData.packImage,
              artistName: itemData.artistName,
              numberSounds: itemData.numberSounds,
              category: itemData.category,
              sounds: itemData.sounds,
            });
          });
          setData(newData);
          console.log(newData, "new data");
        });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchSounds();
  }, []);

  const handleMenuClick = (record, e) => {
    if (e.key === "1") {
      history.push({ pathname: "/dashboard/sounds/updateSound", state: record });
    } else if (e.key === "2") {
      confirm({
        title: `Are you sure delete this record?`,
        onOk() {
          db.collection("SFX").doc(record.id).delete();
          storage.refFromURL(record.packImage).delete();
          if (record.sounds) {
            for (let i = 0; i < record.sounds.length; i++) {
              storage.refFromURL(record.sounds[i].mp3AudioUrl).delete();
              storage.refFromURL(record.sounds[i].wavAudioUrl).delete();
            }
          }
          // record.sounds.map((item)=>{
          //   storage.refFromURL(item.packImage).delete();

          // })
          fetchSounds();
          message.success("Successfully delete!");
        },
      });
    }
  };

  return (
    <div className="App">
      <Form name="control-ref">
        <Row justify="space-between">
          <Col span={8}>
            <Form.Item name="name">
              <Search
                placeholder={`Search Name`}
                // onSearch={this.handleSubmit}
              />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Button type="ghost" onClick={() => history.push("/dashboard/sounds/createSound")}>
              Create
            </Button>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Table
          // {...tableProps}
          pagination
          dataSource={data}
          // className={styles.table}
          bordered
          scroll={{ x: 800 }}
          columns={columns}
          simple
          rowKey={(record) => record.id}
        />
      </div>
    </div>
  );
};

export default SFX;
