import actionTypes from "./constant";

const initialState = {
  adminLogin: null,
  getUsers: [],
  getProducts: [],
  getServices: [],
  getDeals: [],
  getTickets: [],
  getFeedbacks: [],
  getServiceSubscriptions: [],
  getTransactions: [],
  getSingleServiceSubscriptions: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //   admin login
    case actionTypes.ADMIN_LOGIN: {
      let newState = Object.assign({}, state);
      newState.adminLogin = action.payload;
      return newState;
    }

    //   get users
    case actionTypes.GET_USERS: {
      let newState = Object.assign({}, state);
      newState.getUsers = action.payload;
      return newState;
    }

    //   get products
    case actionTypes.GET_PRODUCTS: {
      let newState = Object.assign({}, state);
      newState.getProducts = action.payload;
      return newState;
    }

    //   get services
    case actionTypes.GET_SERVICES: {
      let newState = Object.assign({}, state);
      newState.getServices = action.payload;
      return newState;
    }

    //   get deals
    case actionTypes.GET_DEALS: {
      let newState = Object.assign({}, state);
      newState.getDeals = action.payload;
      return newState;
    }

    //   get tickets
    case actionTypes.GET_TICKETS: {
      let newState = Object.assign({}, state);
      newState.getTickets = action.payload;
      return newState;
    }

    //   get Feedbacks
    case actionTypes.GET_FEEDBACKS: {
      let newState = Object.assign({}, state);
      newState.getFeedbacks = action.payload;
      return newState;
    }

    //   get Service Subscriptions
    case actionTypes.GET_SERVICES_SUBSCRIPTIONS: {
      let newState = Object.assign({}, state);
      newState.getServiceSubscriptions = action.payload;
      return newState;
    }

    //   get Transactions
    case actionTypes.GET_TANSACTIONS: {
      let newState = Object.assign({}, state);
      newState.getTransactions = action.payload;
      return newState;
    }

    //   get Single Service Subscriptions
    case actionTypes.GET_SINGLE_SERVICES_SUBSCRIPTIONS: {
      let newState = Object.assign({}, state);
      newState.getSingleServiceSubscriptions = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

export default reducer;
