import React, { useState, useEffect } from "react";
import { Table, Modal, Avatar, Button, Row, Col, DatePicker, Form, Input, Select, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import DropOption from "../components/DropOption";
import air from "../images/aircraft.png";
import ModalForm from "../components/ModalForm";
import { useDispatch, useSelector } from "react-redux";
import { postDeals, putDeals } from "../store/actions";
import { useForm } from "antd/lib/form/Form";
import { useHistory } from "react-router-dom";
import { auth, db, storage } from "../firebase";

const { confirm } = Modal;
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const LUT = () => {
  const history = useHistory();
  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
  };
  const columns = [
    {
      title: "Pack Name",
      dataIndex: "packName",
      key: "packName",
      width: "10%",
    },
    {
      title: "Pack Image",
      dataIndex: "packImage",
      key: "packImage",
      width: "10%",
      render: (text) => <Avatar style={{ marginLeft: 8 }} src={text} />,
    },
    {
      title: "Before Image",
      dataIndex: "beforeImage",
      key: "beforeImage",
      width: "10%",
      render: (text) => <Avatar style={{ marginLeft: 8 }} src={text} />,
    },
    {
      title: "LUTS",
      dataIndex: "numberLut",
      key: "numberLut",
      width: "20%",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: "20%",
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: "8%",
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: "1", name: `Update` },
              { key: "2", name: `Delete` },
            ]}
          />
        );
      },
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [form] = useForm();
  const dealData = useSelector((state) => state.getDeals);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [uploadImg, setUploadImg] = useState();
  const [uid, setUid] = useState();
  const [data, setData] = useState();

  const handleMenuClick = (record, e) => {
    if (e.key === "1") {
      history.push({ pathname: "/dashboard/lut/update", state: record });
    } else if (e.key === "2") {
      confirm({
        title: `Are you sure delete this record?`,
        onOk() {
          db.collection("lut").doc(record.id).delete();
          storage.refFromURL(record.packImage).delete();
          storage.refFromURL(record.beforeImage).delete();
          if (record.luts) {
            for (let i = 0; i < record.luts.length; i++) {
              storage.refFromURL(record.luts[i].previewImage).delete();
              storage.refFromURL(record.luts[i].afterImage).delete();
              storage.refFromURL(record.luts[i].lutFile).delete();
            }
          }
          fetchLuts();
          message.success("Successfully delete!");
        },
      });
    }
  };

  const fetchLuts = async () => {
    let newData = [];
    try {
      db.collection("lut")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push({
              id: doc.id,
              packName: itemData.packName,
              packImage: itemData.packImage,
              beforeImage: itemData.beforeImage,
              numberLut: itemData.numberLut,
              category: itemData.category,
              luts: itemData.luts,
            });
          });
          setData(newData);
          console.log(newData, "new data");
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchLuts();
  }, []);

  return (
    <div className="App">
      <Form name="control-ref">
        <Row justify="space-between">
          <Col span={8}>
            <Form.Item name="name">
              <Search
                placeholder={`Search Name`}
                // onSearch={this.handleSubmit}
              />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Button type="ghost" onClick={() => history.push("/dashboard/lut/create")}>
              Create
            </Button>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Table
          // {...tableProps}
          pagination
          dataSource={data}
          // className={styles.table}
          bordered
          scroll={{ x: 800 }}
          columns={columns}
          simple
          rowKey={(record) => record.id}
        />
      </div>
    </div>
  );
};

export default LUT;
