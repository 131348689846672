import React, { useState, useEffect } from "react";
import { Form, Row, Col, Input, Button, Upload, message, Radio, Progress } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import MusicForm from "../components/MusicForm";
import { v4 as uuidv4 } from "uuid";
import { auth, db, storage } from "../firebase";
import { useHistory } from "react-router-dom";

export default function CreateMusic() {
  // const [tracks, setTracks] = useState([]);
  const [childRef, setChildRef] = useState([]);
  const [id, setId] = useState(uuidv4());
  const [albumfile, setAlbumFile] = useState();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState();
  const [showProgress, setShowProgress] = useState(false);
  const tracks = React.useRef([]);
  const [form] = Form.useForm();
  const history = useHistory();
  console.log(tracks);
  const handleSubmit = async (value) => {
    for (let i = 0; i < childRef.length; i++) {
      const item = childRef[i];
      await item.ref.current.handleSubmit(value.artistName);
    }
    console.log(value);
    db.collection("music")
      .doc(id)
      .set(
        {
          albumName: value.albumName,
          artistName: value.artistName,
          numberTracks: value.numberTracks,
          tracks: tracks.current,
        },
        { merge: true }
      )
      .then(async () => {
        // Start Loading
        setLoading(true);
        try {
          const url = albumfile ? await uploadFileToFirestore(albumfile, value.albumName, value.artistName) : "";
          console.log(url);
          db.collection("music")
            .doc(id)
            .update({
              albumImage: url,
            })
            .then(() => {
              message.success("Album created!");
              history.push("/dashboard/music");
            });
        } catch (error) {
          alert(`Error: ${error}`);
          setLoading(false);
        }
        setLoading(false);

        // Stop Loading
      })
      .catch(function (error) {
        message.error("Album not created!");
      });
    // Start Loading
  };
  const uploadFileToFirestore = (image, album, artist) =>
    new Promise(async (resolve, reject) => {
      try {
        setShowProgress(true);
        const extension = image.name.split(".");
        const resp = await storage.ref(`songs/${id}/${artist}_${album}.${extension[extension.length - 1]}`);
        const url = await resp.put(image).then(() => resp.getDownloadURL());
        return resolve(url);
      } catch (error) {
        return reject(error);
      }
    });

  useEffect(() => {
    form.setFieldsValue({
      numberTracks: tracks.current.length,
    });
  }, [tracks.current]);
  return (
    <Form form={form} name="control-ref" layout="horizontal" onFinish={handleSubmit}>
      <h2>Album Uploader</h2>
      <Row gutter={24}>
        <Col span={6}>
          {" "}
          <label htmlFor="">Album Name</label>
          <Form.Item name="albumName" rules={[{ required: true }]} hasFeedback>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label htmlFor="">Artist Name</label>
          <Form.Item name="artistName" rules={[{ required: true }]} hasFeedback>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          {" "}
          <label htmlFor="">Select Album Art</label>
          <Form.Item name="albumFile" rules={[{ required: albumfile ? false : true }]} hasFeedback>
            <input type="file" onChange={(e) => setAlbumFile(e.target.files[0])} />
            {showProgress ? <Progress percent={progress} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          {" "}
          <label htmlFor="">Number of Songs</label>
          <Form.Item name="numberTracks" rules={[{ required: true }]} hasFeedback>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Button
        type="primary"
        style={{ marginBottom: "1.5em" }}
        onClick={() => {
          // setLoading(true);
          setChildRef([...childRef, { ref: React.createRef() }]);
          tracks.current = [...tracks.current, { trackID: uuidv4() }];
        }}
      >
        Add Song
      </Button>
      {tracks.current.map((track, index) => (
        <MusicForm
          key={index}
          // ref={childRef}
          ref={childRef[index].ref}
          albumName={id}
          deleteSound={(id) => {
            tracks.current = tracks.current.filter((item) => item.trackID !== id);
            setChildRef(childRef.slice());
          }}
          update={(track) => {
            tracks.current = tracks.current.map((item, ind) => {
              if (index == ind) return track;
              else return item;
            });
          }}
          track={track}
          index={index + 1}
          setLoading={(item) => setLoading(item)}
        />
      ))}
      <Row justify="center">
        <Button type="primary" loading={loading} htmlType="submit" style={{ width: "40%" }}>
          Done
        </Button>
      </Row>
    </Form>
  );
}
