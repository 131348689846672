import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import { Form, Row, Col, Input, Button, Upload, Progress } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { auth, db, storage } from "../firebase";

const LutForm = forwardRef((props, ref) => {
  const [lutId, setLutId] = useState(props.lut.lutID);
  const [lutName, setLutName] = useState(props.lut.lutName);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewImageUrl, setPreviewImageUrl] = useState(props.lut.previewImage ? props.lut.previewImage : "");
  const [afterImage, setAfterImage] = useState(null);
  const [afterImageUrl, setAfterImageUrl] = useState(props.lut.afterImage ? props.lut.afterImage : "");
  const [lutImage, setLutImage] = useState(null);
  const [lutImageUrl, setLutImageUrl] = useState(props.lut.lutFile ? props.lut.lutFile : "");
  const [loading, setLoading] = useState(false);
  const [progressVisible1, setProgressVisible1] = useState(false);
  const [progressVisible2, setProgressVisible2] = useState(false);
  const [progressVisible3, setProgressVisible3] = useState(false);
  const [progress1, setProgress1] = useState(0);
  const [progress2, setProgress2] = useState(0);
  const [progress3, setProgress3] = useState(0);

  useImperativeHandle(ref, () => ({
    async handleSubmit(pack) {
      try {
        props.setLoading(true);
        setLoading(true);
        setProgressVisible1(true);
        const url = previewImage ? await uploadFileToFirestore(previewImage, props.lut.previewImage, pack) : "";
        if (url !== "") {
          setProgress1(100);
        }
        setProgressVisible2(true);
        const url2 = afterImage ? await uploadFileToFirestore(afterImage, props.lut.afterImage, pack) : "";
        if (url2 !== "") {
          setProgress2(100);
        }
        setProgressVisible3(true);
        const url3 = lutImage ? await uploadFileToFirestore(lutImage, props.lut.lutFile, pack) : "";
        if (url3 !== "") {
          setProgress3(100);
        }

        setPreviewImageUrl(url);
        setAfterImageUrl(url2);
        setLutImageUrl(url3);
        await props.update({
          lutID: lutId,
          lutName: lutName,
          previewImage: url ? url : previewImageUrl,
          afterImage: url2 ? url2 : afterImageUrl,
          lutFile: url3 ? url3 : lutImageUrl,
        });
        props.setLoading(false);
        setLoading(false);
      } catch (error) {
        alert(`Error: ${error}`);
        props.setLoading(false);
        setLoading(false);
      }
    },
  }));

  const uploadFileToFirestore = (image, oldImage, pack) =>
    new Promise(async (resolve, reject) => {
      try {
        const extension = image.name.split(".");
        const deleteFile = oldImage ? await storage.refFromURL(oldImage).delete() : "";
        const resp = await storage.ref(
          `Lut/${props.packName}/${lutId}/${pack}_${lutName}.${extension[extension.length - 1]}`
        );
        const url = await resp.put(image).then(() => resp.getDownloadURL());
        return resolve(url);
      } catch (error) {
        return reject(error);
      }
    });

  return (
    <div style={{ borderTop: "1px solid #484848", padding: "1.5em 0" }}>
      <h2
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span>LUT {props.index}</span>{" "}
        <CloseCircleOutlined
          onClick={() => {
            props.deleteSound(lutId);
            storage.refFromURL(previewImageUrl).delete();
            storage.refFromURL(afterImageUrl).delete();
            storage.refFromURL(lutImageUrl).delete();
          }}
        />
      </h2>
      <Row gutter={24}>
        <Col span={6}>
          {" "}
          <label htmlFor="">LUT Name</label>
          <Form.Item name={`lut_${props.index}`} rules={[{ required: true }]} hasFeedback>
            <Input value={lutName} onChange={(e) => setLutName(e.target.value)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <label htmlFor="">Select Preview Image</label>
          <Form.Item
            name={`lut_${props.index}_preview`}
            rules={[{ required: previewImage || previewImageUrl ? false : true }]}
            hasFeedback
          >
            <input type="file" accept=".jpg" onChange={(e) => setPreviewImage(e.target.files[0])} />
            {progressVisible1 ? <Progress percent={progress1} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <label htmlFor="">Select After Image</label>
          <Form.Item
            name={`lut_${props.index}_after`}
            rules={[{ required: afterImage || afterImageUrl ? false : true }]}
            hasFeedback
          >
            <input type="file" accept=".jpg" onChange={(e) => setAfterImage(e.target.files[0])} />
            {progressVisible2 ? <Progress percent={progress2} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <label htmlFor="">Select LUT File</label>
          <Form.Item
            name={`lut_${props.index}_lutfile`}
            rules={[{ required: lutImage || lutImageUrl ? false : true }]}
            hasFeedback
          >
            <input type="file" accept=".cube" onChange={(e) => setLutImage(e.target.files[0])} />
            {progressVisible3 ? <Progress percent={progress3} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
});

export default LutForm;
