import React, { useState, useRef, useEffect } from "react";
import { Table, Modal, Button, Row, Col, DatePicker, Form, Input, Select, message } from "antd";
import { Link } from "react-router-dom";
import DropOption from "../components/DropOption";
import ModalForm from "../components/ModalForm";
import { useDispatch, useSelector } from "react-redux";
import { delUsers, postUsers, putUsers } from "../store/actions";
import { useAuth } from "../contexts/AuthContext";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { CountryDropdown, RegionDropdown, CountryRegionData } from "react-country-region-selector";

import { auth, db, googleProvider } from "../firebase";
const { confirm } = Modal;
const { Search } = Input;
const { RangePicker } = DatePicker;

const Users = () => {
  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
  };
  const columns = [
    {
      title: "First Name",
      dataIndex: "firstname",
      key: "name",
      width: "10%",
    },

    {
      title: "Last Name",
      dataIndex: "lastname",
      key: "description",
      width: "10%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "description",
      width: "20%",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "link",
      width: "10%",
    },

    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: "8%",
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: "1", name: `Update` },
              { key: "2", name: `Delete` },
            ]}
          />
        );
      },
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [uid, setUid] = useState();
  const { currentUser } = useAuth();
  const [data, setData] = useState([]);

  const fetchUserName = async () => {
    try {
      db.collection("users")
        .get()
        .then((querySnapshot) => {
          let newData = [];

          querySnapshot.forEach((doc) => {
            const itemData = doc.data();

            newData.push({
              id: doc.id,
              firstName: itemData.firstname,
              lastName: itemData.lastname,
              email: itemData.email,
              password: itemData.password,
              plan: itemData.plan,
              country: itemData.country,
              stripeid: itemData.stripeid,
              gender: itemData.gender,
              role: itemData.role,
            });
          });
          setData(newData);
        });

      db.collection("plans")
        .get()
        .then((querySnapshot) => {
          let newData = [];

          querySnapshot.forEach((doc) => {
            const itemData = doc.data();

            newData.push({
              id: doc.id,
              planname: itemData.planname,
              description: itemData.description,
              lutaccess: itemData.lutaccess,
              monthprice: itemData.monthprice,
              musicaccess: itemData.musicaccess,
              soundaccess: itemData.soundaccess,
              yearprice: itemData.yearprice,
            });
          });
          setPlans(newData);
        });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchUserName();
  }, []);

  const handleMenuClick = (record, e) => {
    if (e.key === "1") {
      console.log(record);
      setEditModalVisible(true);
      // setUid(record._id);
      form.setFieldsValue({
        firstname: record.firstname,
        lastname: record.lastname,
        email: record.email,
        id: record.id,
        password: record.password,
        plan: record.plan,
        country: record.country,
        stripeid: record.stripeid,
        gender: record.gender,
        role: record.role,
      });

      // setEditRecord(record)
    } else if (e.key === "2") {
      confirm({
        title: `Are you sure delete this record?`,
        onOk() {
          const data = { uid: record.id };
          const config = {
            method: "delete",
            url: `https://us-central1-creatorjams.cloudfunctions.net/app/user/delete`,
            data,
            headers: {},
          };
          axios(config)
            .then(function (response) {
              db.collection("users").doc(record.id).delete();
              fetchUserName();
              message.success("Successfully delete!");
            })
            .catch(function (error) {});

          // console.log(record.id);
          // dispatch(delUsers(record._id));
        },
      });
    }
  };

  const { Option } = Select;
  const handleAddUser = async (value) => {
    console.log(value);
    const data = {
      email: value.email,
      password: value.password,
      displayName: value.firstname + value.lastname,
    };
    setLoading(true);
    try {
      await axios.post("https://us-central1-creatorjams.cloudfunctions.net/app/user/create", data).then((res) => {
        console.log(res);
        db.collection("users").doc(res.data.uid).set({
          firstname: value.firstname,
          lastname: value.lastname,
          email: value.email,
          id: res.data.uid,
          password: value.password,
          plan: value.plan,
          country: value.country,
          stripeid: value.stripeid,
          gender: value.gender,
          role: value.role,
        });
        fetchUserName();
        setLoading(false);
        setIsModalVisible(false);
      });
    } catch (error) {
      message.error(error.response.data.message);
      setLoading(false);
    }
  };
  // setLoading(true);
  // db.collection("users").doc(uuidv4()).set({
  //   firstName: value.FirstName,
  //   lastName: value.LastName,
  //   email: value.Email,
  // });
  // fetchUserName();
  // setLoading(false);
  // setIsModalVisible(false);
  // };

  const handleEditUser = async (value) => {
    const data = {
      email: value.email,
      uid: value.id,
      displayName: value.firstname + value.lastname,
    };
    const config = {
      method: "put",
      url: `https://us-central1-creatorjams.cloudfunctions.net/app/user/update`,
      data,
      headers: {},
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        console.log("res", response);
        db.collection("users")
          .doc(value.id)
          .update({
            firstname: value.firstname,
            lastname: value.lastname,
            email: value.email,
            id: value.id,
            password: value.password,
            plan: value.plan,
            country: value.country,
            stripeid: value.stripeid,
            gender: value.gender,
            role: value.role,
            adminId: value.id,
          })
          .then((doc) => {
            console.log("user updated!");
          });
        fetchUserName();
        setLoading(false);
        setEditModalVisible(false);
      })
      .catch(function (error) {
        message.error(error.response.data.message);
        setLoading(false);
        console.log(`${error.response.data.message}`);
      });
  };

  return (
    <div className="App">
      <ModalForm isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}>
        <Form name="control-ref" layout="horizontal" onFinish={handleAddUser}>
          <Form.Item name="firstname" rules={[{ required: true }]} label={`FirstName`} hasFeedback {...formItemLayout}>
            <Input />
          </Form.Item>

          <Form.Item name="lastname" rules={[{ required: true }]} label={`LastName`} hasFeedback {...formItemLayout}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
            hasFeedback
            {...formItemLayout}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm"
            dependencies={["password"]}
            {...formItemLayout}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error("The two passwords that you entered do not match!"));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[{ required: true }, { type: "email" }]}
            label={`Email`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item name="role" rules={[{ required: true }]} label={`Role`} hasFeedback {...formItemLayout}>
            <Select size="large" allowClear style={{ width: "100%" }} placeholder="Select a role">
              <Option value="admin">admin</Option>
              <Option value="user">user</Option>
            </Select>
          </Form.Item>
          <Form.Item name="country" rules={[{ required: true }]} label={`Country`} hasFeedback {...formItemLayout}>
            <Select size="large" allowClear style={{ width: "100%" }} placeholder="Select a country">
              {CountryRegionData.map((item) => (
                <Option value={item[1]}>{item[0]}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="gender" rules={[{ required: true }]} label={`Gender`} hasFeedback {...formItemLayout}>
            <Select size="large" allowClear style={{ width: "100%" }} placeholder="Select a gender">
              <Option value="male">male</Option>
              <Option value="female">female</Option>
            </Select>
          </Form.Item>
          <Form.Item name="plan" rules={[{ required: true }]} label={`Plan`} hasFeedback {...formItemLayout}>
            <Select size="large" allowClear style={{ width: "100%" }} placeholder="Select a plan">
              {plans.map((item) => (
                <Option value={item.id}>{item.planname}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="stripeid" rules={[{ required: true }]} label={`Stripe ID`} hasFeedback {...formItemLayout}>
            <Input />
          </Form.Item>
          <Row justify="center">
            <Button type="primary" htmlType="submit" loading={loading} style={{ width: "80%" }}>
              Add User
            </Button>
          </Row>
        </Form>
      </ModalForm>

      <ModalForm isModalVisible={editModalVisible} setIsModalVisible={setEditModalVisible}>
        <Form name="edit-form" form={form} layout="horizontal" onFinish={handleEditUser}>
          <Form.Item name="firstname" rules={[{ required: true }]} label={`FirstName`} hasFeedback {...formItemLayout}>
            <Input />
          </Form.Item>
          <Form.Item
            style={{ display: "none" }}
            name="id"
            rules={[{ required: true }]}
            label={`ID`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item name="lastname" rules={[{ required: true }]} label={`LastName`} hasFeedback {...formItemLayout}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
            hasFeedback
            {...formItemLayout}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm"
            dependencies={["password"]}
            {...formItemLayout}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error("The two passwords that you entered do not match!"));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[{ required: true }, { type: "email" }]}
            label={`Email`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item name="role" rules={[{ required: true }]} label={`Role`} hasFeedback {...formItemLayout}>
            <Select size="large" allowClear style={{ width: "100%" }} placeholder="Select a role">
              <Option value="admin">admin</Option>
              <Option value="user">user</Option>
            </Select>
          </Form.Item>
          <Form.Item name="country" rules={[{ required: true }]} label={`Country`} hasFeedback {...formItemLayout}>
            <Select size="large" allowClear style={{ width: "100%" }} placeholder="Select a country">
              {CountryRegionData.map((item) => (
                <Option value={item[1]}>{item[0]}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="gender" rules={[{ required: true }]} label={`Gender`} hasFeedback {...formItemLayout}>
            <Select size="large" allowClear style={{ width: "100%" }} placeholder="Select a gender">
              <Option value="male">male</Option>
              <Option value="female">female</Option>
            </Select>
          </Form.Item>
          <Form.Item name="plan" rules={[{ required: true }]} label={`Plan`} hasFeedback {...formItemLayout}>
            <Select size="large" allowClear style={{ width: "100%" }} placeholder="Select a plan">
              {plans.map((item) => (
                <Option value={item.id}>{item.planname}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="stripeid" rules={[{ required: true }]} label={`Stripe ID`} hasFeedback {...formItemLayout}>
            <Input />
          </Form.Item>

          <Row justify="center">
            <Button type="primary" htmlType="submit" loading={loading} style={{ width: "80%" }}>
              Edit User
            </Button>
          </Row>
        </Form>
      </ModalForm>
      <Form name="control-ref">
        <Row justify="space-between">
          <Col span={8}>
            <Form.Item name="name">
              <Search
                placeholder={`Search Name`}
                // onSearch={this.handleSubmit}
              />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Button type="ghost" onClick={() => setIsModalVisible(true)}>
              Create
            </Button>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Table
          // {...tableProps}
          pagination
          dataSource={data}
          // className={styles.table}
          bordered
          scroll={{ x: 800 }}
          columns={columns}
          simple
          rowKey={(record) => record.id}
        />
      </div>
    </div>
  );
};

export default Users;
