import React, { useState } from "react";
import {
  Table,
  Modal,
  Avatar,
  Button,
  Row,
  Col,
  DatePicker,
  Form,
  Input,
  Select,
  Upload,
} from "antd";

import {  useLocation} from "react-router-dom";


const { confirm } = Modal;
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const Messages = () => {
  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
  };
  const columns = [
    {
      title: "Messages",
      dataIndex: "message",
      key: "name",
      width: "20%",
    },
    {
      title: "Attachment",
      dataIndex: "attachments",
      key: "description",
      width: "10%",
      render: (text) => <Avatar style={{ marginLeft: 8 }} src={`https://hatch-core.herokuapp.com/img/tickets/${text}`} />,
    },
   

   
  ];
  const location = useLocation()
  return (
    <div className="App">

      <Form name="control-ref">
        <Row justify="space-between">
          <Col span={8}>
            <Form.Item name="name">
              <Search
                placeholder={`Search Name`}
                // onSearch={this.handleSubmit}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Table
          // {...tableProps}
          pagination
          dataSource={location.data}
          // className={styles.table}
          bordered
          scroll={{ x: 800 }}
          columns={columns}
          simple
          rowKey={(record) => record.id}
        />
      </div>
    </div>
  );
};

export default Messages;
