import React, { useState, useEffect } from "react";
import { Form, Row, Col, Input, Button, Upload, message, Progress } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import LutForm from "../components/LutForm";
import { v4 as uuidv4 } from "uuid";
import { auth, db, storage } from "../firebase";
import { useHistory } from "react-router-dom";

export default function UpdateLut(props) {
  // const [luts, setLuts] = useState(props.location.state.luts);
  const [childRef, setChildRef] = useState([{ ref: React.createRef() }]);
  const [id, setId] = useState(props.location.state.id);
  const [packImagefile, setPackImageFile] = useState(null);
  const [beforeImagefile, setBeforeImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progressVisible1, setProgressVisible1] = useState(false);
  const [progressVisible2, setProgressVisible2] = useState(false);
  const [progress1, setProgress1] = useState(0);
  const [progress2, setProgress2] = useState(0);
  const luts = React.useRef([]);
  const [form] = Form.useForm();
  const history = useHistory();

  const handleSubmit = async (value) => {
    for (let i = 0; i < childRef.length; i++) {
      const item = childRef[i];
      if (item.ref.current) {
        console.log(item.ref);
        await item.ref.current.handleSubmit(value.packName);
      }
    }
    console.log(value);
    db.collection("lut")
      .doc(value.id)
      .update(
        {
          packName: value.packName,
          category: value.category,
          numberLut: value.numberLut,
          luts: luts.current,
          downloads: 0,
        },
        { merge: true }
      )
      .then(async () => {
        setLoading(true);
        try {
          setProgressVisible1(true);
          const url1 = packImagefile
            ? await uploadFileToFirestore(packImagefile, props.location.state.packImage, value.packName)
            : "";
          if (url1) {
            await db.collection("lut").doc(id).update({
              packImage: url1,
            });
            setProgress1(100);
          }
          setProgressVisible2(true);
          const url2 = beforeImagefile
            ? await uploadFileToFirestore(beforeImagefile, props.location.state.beforeImage, value.packName, "before")
            : "";

          if (url2) {
            await db
              .collection("lut")
              .doc(id)
              .update({
                beforeImage: url2,
              })
              .then(() => {
                setProgress2(100);
              });
          }
        } catch (error) {
          alert(`Error: ${error}`);
        }
        setLoading(false);
        message.success("Pack Updated!");
        history.push("/dashboard/lut");
        // Stop Loading
      })
      .catch(function (error) {
        message.error("Pack not Updated!");
      });
  };
  const uploadFileToFirestore = (image, oldImage, pack, before) =>
    new Promise(async (resolve, reject) => {
      try {
        const extension = image.name.split(".");
        const deleteFile = oldImage ? await storage.refFromURL(oldImage).delete() : "";
        const resp = await storage.ref(
          `Lut/${id}/${pack}${before ? `_${before}` : ""}.${extension[extension.length - 1]}`
        );
        const url = await resp.put(image).then(() => resp.getDownloadURL());
        return resolve(url);
      } catch (error) {
        return reject(error);
      }
    });
  useEffect(() => {
    form.setFieldsValue({
      numberLut: luts.current.length,
    });
  }, [luts.current]);
  useEffect(() => {
    const record = props.location.state;
    const lutsData = {};
    for (let i = 0; i < record.luts.length; i++) {
      lutsData[`lut_${i + 1}`] = record.luts[i].lutName;
    }

    const newFieldsValues = {
      id: record.id,
      packName: record.packName,
      category: record.category,
      numberLut: record.numberLut,
      ...lutsData,
    };
    form.setFieldsValue(newFieldsValues);
    for (let i = 0; i < props.location.state.luts.length; i++) {
      setChildRef([...childRef, { ref: React.createRef() }]);
    }
    console.log(childRef);
    luts.current = props.location.state.luts;
  }, []);
  return (
    <Form form={form} name="control-ref" layout="horizontal" onFinish={handleSubmit}>
      <h2>LUT Uploader</h2>
      <Row gutter={24}>
        <Col span={6}>
          {" "}
          <label htmlFor="">Pack Name</label>
          <Form.Item name="packName" rules={[{ required: true }]} hasFeedback>
            <Input />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="id" rules={[{ required: true }]} hasFeedback>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label htmlFor="">Category</label>
          <Form.Item name="category" rules={[{ required: true }]} hasFeedback>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          {" "}
          <label htmlFor="">Select Pack Image</label>
          <Form.Item
            name="packImage"
            rules={[{ required: packImagefile || props.location.state.packImage ? false : true }]}
            hasFeedback
          >
            <input type="file" accept=".jpg" onChange={(e) => setPackImageFile(e.target.files[0])} />
            {progressVisible1 ? <Progress percent={progress1} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
        <Col span={6}>
          {" "}
          <label htmlFor="">Select Before Image</label>
          <Form.Item
            name="beforeImage"
            rules={[{ required: beforeImagefile || props.location.state.beforeImage ? false : true }]}
            hasFeedback
          >
            <input type="file" accept=".jpg" onChange={(e) => setBeforeImageFile(e.target.files[0])} />
            {progressVisible2 ? <Progress percent={progress2} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          {" "}
          <label htmlFor="">Number of LUTS</label>
          <Form.Item name="numberLut" rules={[{ required: true }]} hasFeedback>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Button
        type="primary"
        style={{ marginBottom: "1.5em" }}
        onClick={() => {
          // setLoading(true);
          setChildRef([...childRef, { ref: React.createRef() }]);
          luts.current = [...luts.current, { lutID: uuidv4() }];
        }}
      >
        Add Lut
      </Button>
      {luts.current.map((lut, index) => (
        <LutForm
          key={index}
          ref={childRef[index].ref}
          packName={id}
          deleteSound={(id) => {
            luts.current = luts.current.filter((item) => item.lutID !== id);
            setChildRef(childRef.slice());
          }}
          update={(lut) => {
            luts.current = luts.current.map((item, ind) => {
              if (index == ind) return lut;
              else return item;
            });
          }}
          lut={lut}
          index={index + 1}
          setLoading={(item) => setLoading(item)}
        />
      ))}
      <Row justify="center">
        <Button loading={loading} type="primary" htmlType="submit" style={{ width: "40%" }}>
          Done
        </Button>
      </Row>
    </Form>
  );
}
