import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import { Form, Row, Col, Input, Button, Upload, Radio, Progress } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { auth, db, storage } from "../firebase";

const MusicForm = forwardRef((props, ref) => {
  const [trackId, setTrackId] = useState(props.track.trackID);
  const [trackName, setTrackName] = useState(props.track.trackName);
  const [genre, setGenre] = useState(props.track.genre);
  const [instrument, setInstrument] = useState(props.track.instrument);
  const [mood1, setMood1] = useState(props.track.mood1);
  const [mood2, setMood2] = useState(props.track.mood2);
  const [bpm, setBpm] = useState(props.track.bpm);
  const [key, setKey] = useState(props.track.key);
  const [vocals, setVocals] = useState(props.track.vocals);
  const [fullmixmp3Url, setFullmixmp3Url] = useState(props.track.fullmixmp3 ? props.track.fullmixmp3 : "");
  const [fullmixwavUrl, setFullmixwavUrl] = useState(props.track.fullmixwav ? props.track.fullmixwav : "");
  const [drumsmp3Url, setDrumsmp3Url] = useState(props.track.drumsmp3 ? props.track.drumsmp3 : "");
  const [drumswavUrl, setDrumswavUrl] = useState(props.track.drumswav ? props.track.drumswav : "");
  const [bassmp3Url, setBassmp3Url] = useState(props.track.bassmp3 ? props.track.bassmp3 : "");
  const [basswavUrl, setBasswavUrl] = useState(props.track.basswav ? props.track.basswav : "");
  const [instmp3Url, setInstmp3Url] = useState(props.track.instmp3 ? props.track.instmp3 : "");
  const [instwavUrl, setInstwavUrl] = useState(props.track.instwav ? props.track.instwav : "");
  const [vocalsmp3Url, setVocalsmp3Url] = useState(props.track.vocalsmp3 ? props.track.vocalsmp3 : "");
  const [vocalswavUrl, setVocalswavUrl] = useState(props.track.vocalswav ? props.track.vocalswav : "");
  const [fullmixmp3, setFullmixmp3] = useState(null);
  const [fullmixwav, setFullmixwav] = useState(null);
  const [drumsmp3, setDrumsmp3] = useState(null);
  const [drumswav, setDrumswav] = useState(null);
  const [bassmp3, setBassmp3] = useState(null);
  const [basswav, setBasswav] = useState(null);
  const [instmp3, setInstmp3] = useState(null);
  const [instwav, setInstwav] = useState(null);
  const [vocalsmp3, setVocalsmp3] = useState(null);
  const [vocalswav, setVocalswav] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progressVisible1, setProgressVisible1] = useState(false);
  const [progressVisible2, setProgressVisible2] = useState(false);
  const [progressVisible3, setProgressVisible3] = useState(false);
  const [progressVisible4, setProgressVisible4] = useState(false);
  const [progressVisible5, setProgressVisible5] = useState(false);
  const [progressVisible6, setProgressVisible6] = useState(false);
  const [progressVisible7, setProgressVisible7] = useState(false);
  const [progressVisible8, setProgressVisible8] = useState(false);
  const [progressVisible9, setProgressVisible9] = useState(false);
  const [progressVisible10, setProgressVisible10] = useState(false);
  const [progress1, setProgress1] = useState(0);
  const [progress2, setProgress2] = useState(0);
  const [progress3, setProgress3] = useState(0);
  const [progress4, setProgress4] = useState(0);
  const [progress5, setProgress5] = useState(0);
  const [progress6, setProgress6] = useState(0);
  const [progress7, setProgress7] = useState(0);
  const [progress8, setProgress8] = useState(0);
  const [progress9, setProgress9] = useState(0);
  const [progress10, setProgress10] = useState(0);

  useImperativeHandle(ref, () => ({
    async handleSubmit(artist) {
      try {
        props.setLoading(true);
        setLoading(true);
        setProgressVisible1(true);
        const url2 = fullmixwav
          ? await uploadFileToFirestore(fullmixwav, props.track.fullmixwav, artist, "fullmix")
          : "";
        if (url2 !== "") {
          setProgress1(100);
        }
        setProgressVisible2(true);
        const url = fullmixmp3
          ? await uploadFileToFirestore(fullmixmp3, props.track.fullmixmp3, artist, "fullmix")
          : "";
        if (url !== "") {
          setProgress2(100);
        }
        setProgressVisible3(true);
        const url4 = drumswav ? await uploadFileToFirestore(drumswav, props.track.drumswav, artist, "drums") : "";
        if (url4 !== "") {
          setProgress3(100);
        }
        setProgressVisible4(true);
        const url3 = drumsmp3 ? await uploadFileToFirestore(drumsmp3, props.track.drumsmp3, artist, "drums") : "";
        if (url3 !== "") {
          setProgress4(100);
        }
        setProgressVisible5(true);
        const url6 = basswav ? await uploadFileToFirestore(basswav, props.track.basswav, artist, "bass") : "";
        if (url6 !== "") {
          setProgress5(100);
        }
        setProgressVisible6(true);
        const url5 = bassmp3 ? await uploadFileToFirestore(bassmp3, props.track.bassmp3, artist, "bass") : "";
        if (url5 !== "") {
          setProgress6(100);
        }
        setProgressVisible7(true);
        const url8 = instwav ? await uploadFileToFirestore(instwav, props.track.instwav, artist, "inst") : "";
        if (url8 !== "") {
          setProgress7(100);
        }
        setProgressVisible8(true);
        const url7 = instmp3 ? await uploadFileToFirestore(instmp3, props.track.instmp3, artist, "inst") : "";
        if (url7 !== "") {
          setProgress8(100);
        }
        setProgressVisible9(true);
        const url10 = vocalswav ? await uploadFileToFirestore(vocalswav, props.track.vocalswav, artist, "vocals") : "";
        if (url10 !== "") {
          setProgress9(100);
        }
        setProgressVisible10(true);
        const url9 = vocalsmp3 ? await uploadFileToFirestore(vocalsmp3, props.track.vocalsmp3, artist, "vocals") : "";
        if (url9 !== "") {
          setProgress10(100);
        }
        setProgressVisible1(true);

        setFullmixmp3Url(url);
        setFullmixwavUrl(url);
        setDrumsmp3Url(url);
        setDrumswavUrl(url);
        setBassmp3Url(url);
        setBasswavUrl(url);
        setInstmp3Url(url);
        setInstwavUrl(url);
        setVocalsmp3Url(url);
        setVocalswavUrl(url);
        await props.update({
          trackID: trackId,
          trackName: trackName,
          genre: genre,
          instrument: instrument,
          mood1: mood1,
          mood2: mood2,
          bpm: bpm,
          key: key,
          vocals: vocals,
          fullmixmp3: url ? url : fullmixmp3Url,
          fullmixwav: url2 ? url2 : fullmixwavUrl,
          drumsmp3: url3 ? url3 : drumsmp3Url,
          drumswav: url4 ? url4 : drumswavUrl,
          bassmp3: url5 ? url5 : bassmp3Url,
          basswav: url6 ? url6 : basswavUrl,
          instmp3: url7 ? url7 : instmp3Url,
          instwav: url8 ? url8 : instwavUrl,
          vocalsmp3: url9 ? url9 : vocalsmp3Url,
          vocalswav: url10 ? url10 : vocalswavUrl,
          saveData: true,
          trackPlays: 0,
          downloads: 0,
          averageDurationPlayed: 0,
        });
        props.setLoading(false);
        setLoading(false);
      } catch (error) {
        alert(`Error: ${error}`);
        props.setLoading(false);
        setLoading(false);
      }
    },
  }));
  const uploadFileToFirestore = (image, oldImage, artist, fileName) =>
    new Promise(async (resolve, reject) => {
      try {
        const extension = image.name.split(".");
        const deleteFile = oldImage ? await storage.refFromURL(oldImage).delete() : "";
        const resp = await storage.ref(
          `songs/${props.albumName}/${trackId}/${artist}_${trackName}_${bpm}bpm_${fileName}.${
            extension[extension.length - 1]
          }`
        );
        const url = await resp.put(image).then(() => resp.getDownloadURL());
        return resolve(url);
      } catch (error) {
        return reject(error);
      }
    });

  return (
    <div style={{ borderTop: "1px solid #484848", padding: "1.5em 0" }}>
      <h2
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span>Track {props.index}</span>{" "}
        <CloseCircleOutlined
          onClick={() => {
            props.deleteSound(trackId);
            storage.refFromURL(fullmixmp3Url).delete();
            storage.refFromURL(fullmixwavUrl).delete();
            storage.refFromURL(drumsmp3Url).delete();
            storage.refFromURL(drumswavUrl).delete();
            storage.refFromURL(bassmp3Url).delete();
            storage.refFromURL(basswavUrl).delete();
            storage.refFromURL(instmp3Url).delete();
            storage.refFromURL(instwavUrl).delete();
            storage.refFromURL(vocalsmp3Url).delete();
            storage.refFromURL(vocalswavUrl).delete();
          }}
        />
      </h2>
      <Row gutter={24}>
        <Col span={6}>
          {" "}
          <label htmlFor="">Track Name</label>
          <Form.Item name={`trackName_${props.index}`} rules={[{ required: true }]} hasFeedback>
            <Input value={trackName} onChange={(e) => setTrackName(e.target.value)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          {" "}
          <label htmlFor="">Genre</label>
          <Form.Item name={`trackName_${props.index}_genre`} rules={[{ required: true }]} hasFeedback>
            <Input value={genre} onChange={(e) => setGenre(e.target.value)} />
          </Form.Item>
        </Col>
        <Col span={6}>
          {" "}
          <label htmlFor="">Main Instrument</label>
          <Form.Item name={`trackName_${props.index}_instrument`} rules={[{ required: true }]} hasFeedback>
            <Input value={instrument} onChange={(e) => setInstrument(e.target.value)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          {" "}
          <label htmlFor="">Mood 1</label>
          <Form.Item name={`trackName_${props.index}_mood1`} rules={[{ required: true }]} hasFeedback>
            <Input value={mood1} onChange={(e) => setMood1(e.target.value)} />
          </Form.Item>
        </Col>
        <Col span={6}>
          {" "}
          <label htmlFor="">Mood 2</label>
          <Form.Item name={`trackName_${props.index}_mood2`} rules={[{ required: true }]} hasFeedback>
            <Input value={mood2} onChange={(e) => setMood2(e.target.value)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          {" "}
          <label htmlFor="">BPM</label>
          <Form.Item name={`trackName_${props.index}_bpm`} rules={[{ required: true }]} hasFeedback>
            <Input value={bpm} onChange={(e) => setBpm(e.target.value)} />
          </Form.Item>
        </Col>
        <Col span={6}>
          {" "}
          <label htmlFor="">Key</label>
          <Form.Item name={`trackName_${props.index}_key`} rules={[{ required: true }]} hasFeedback>
            <Input value={key} onChange={(e) => setKey(e.target.value)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginBottom: "24px" }}>
        <Col span={6}>
          {" "}
          <label htmlFor="">Vocals?</label>
          <Form.Item name={`trackName_${props.index}_vocals`} rules={[{ required: true }]} hasFeedback>
            <Radio.Group onChange={(e) => setVocals(e.target.value)} value={vocals}>
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <label htmlFor="">Select HQ Full Mix</label>

          <Form.Item
            name={`trackName_${props.index}_fullmixwav`}
            rules={[{ required: fullmixwav || fullmixwavUrl ? false : true }]}
            hasFeedback
          >
            <input type="file" onChange={(e) => setFullmixwav(e.target.files[0])} accept=".wav" />

            {progressVisible1 ? <Progress percent={progress1} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
        <Col span={6}>
          <label htmlFor="">Select MP3 Full Mix</label>
          <Form.Item
            name={`trackName_${props.index}_fullmixmp3`}
            rules={[{ required: fullmixmp3 || fullmixmp3Url ? false : true }]}
            hasFeedback
          >
            <input type="file" onChange={(e) => setFullmixmp3(e.target.files[0])} accept=".mp3" />
            {progressVisible2 ? <Progress percent={progress2} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <label htmlFor="">Select HQ Drums Stem</label>

          <Form.Item
            name={`trackName_${props.index}_drumswav`}
            rules={[{ required: drumswav || drumswavUrl ? false : true }]}
            hasFeedback
          >
            <input type="file" onChange={(e) => setDrumswav(e.target.files[0])} accept=".wav" />
            {progressVisible3 ? <Progress percent={progress3} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
        <Col span={6}>
          <label htmlFor="">Select MP3 Drums Stem</label>

          <Form.Item
            name={`trackName_${props.index}_drumsmp3`}
            rules={[{ required: drumsmp3 || drumsmp3Url ? false : true }]}
            hasFeedback
          >
            <input type="file" onChange={(e) => setDrumsmp3(e.target.files[0])} accept=".mp3" />
            {progressVisible4 ? <Progress percent={progress4} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <label htmlFor="">Select HQ Bass Stem</label>
          <Form.Item
            name={`trackName_${props.index}_basswav`}
            rules={[{ required: basswav || basswavUrl ? false : true }]}
            hasFeedback
          >
            <input type="file" onChange={(e) => setBasswav(e.target.files[0])} accept=".wav" />
            {progressVisible5 ? <Progress percent={progress5} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
        <Col span={6}>
          <label htmlFor="">Select MP3 Bass Stem</label>
          <Form.Item
            name={`trackName_${props.index}_bassmp3`}
            rules={[{ required: bassmp3 || bassmp3Url ? false : true }]}
            hasFeedback
          >
            <input type="file" onChange={(e) => setBassmp3(e.target.files[0])} accept=".mp3" />
            {progressVisible6 ? <Progress percent={progress6} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <label htmlFor="">Select HQ Instrument Stem</label>
          <Form.Item
            name={`trackName_${props.index}_instawav`}
            rules={[{ required: instwav || instwavUrl ? false : true }]}
            hasFeedback
          >
            <input type="file" onChange={(e) => setInstwav(e.target.files[0])} accept=".wav" />
            {progressVisible7 ? <Progress percent={progress7} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
        <Col span={6}>
          <label htmlFor="">Select MP3 Instrument Stem</label>
          <Form.Item
            name={`trackName_${props.index}_instamp3`}
            rules={[{ required: instmp3 || instmp3Url ? false : true }]}
            hasFeedback
          >
            <input type="file" onChange={(e) => setInstmp3(e.target.files[0])} accept=".mp3" />
            {progressVisible8 ? <Progress percent={progress8} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <label htmlFor="">Select HQ Vocal Stem</label>
          <Form.Item
            name={`trackName_${props.index}_vocalswav`}
            rules={[{ required: vocalswav || vocalswavUrl ? false : true }]}
            hasFeedback
          >
            <input type="file" onChange={(e) => setVocalswav(e.target.files[0])} accept=".wav" />
            {progressVisible9 ? <Progress percent={progress9} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
        <Col span={6}>
          <label htmlFor="">Select MP3 Vocal Stem</label>
          <Form.Item
            name={`trackName_${props.index}_vocalsmp3`}
            rules={[{ required: vocalsmp3 || vocalsmp3Url ? false : true }]}
            hasFeedback
          >
            <input type="file" onChange={(e) => setVocalsmp3(e.target.files[0])} accept=".mp3" />
            {progressVisible10 ? <Progress percent={progress10} showInfo={false} /> : ""}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
});
export default MusicForm;
