import React, { useState, useEffect } from "react";
import {
  Table,
  Modal,
  Avatar,
  Button,
  Row,
  Col,
  DatePicker,
  Form,
  Input,
  Select,
  Upload,
  message,
  InputNumber,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import DropOption from "../components/DropOption";
import ModalForm from "../components/ModalForm";
import { useDispatch, useSelector } from "react-redux";
import { delProducts, postProducts, putProducts } from "../store/actions";
import { auth, db, googleProvider } from "../firebase";
import { v4 as uuidv4 } from "uuid";
const { confirm } = Modal;
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const Products = () => {
  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
  };
  const columns = [
    {
      title: "Plan Name",
      dataIndex: "planname",
      key: "name",
      width: "10%",
    },
    {
      title: "Month Price",
      dataIndex: "monthprice",
      key: "image",
      width: "10%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "20%",
    },
    {
      title: "Year Price",
      dataIndex: "yearprice",
      key: "link",
      width: "10%",
    },

    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: "8%",
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: "1", name: `Update` },
              { key: "2", name: `Delete` },
            ]}
          />
        );
      },
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const productData = useSelector((state) => state.getProducts);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [uploadImg, setUploadImg] = useState();
  const [uid, setUid] = useState();
  const [data, setData] = useState();

  const fetchPlans = async () => {
    let newData = [];
    try {
      db.collection("plans")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push({
              id: doc.id,
              planname: itemData.planname,
              description: itemData.description,
              lutaccess: itemData.lutaccess,
              monthprice: itemData.monthprice,
              musicaccess: itemData.musicaccess,
              soundaccess: itemData.soundaccess,
              yearprice: itemData.yearprice,
            });
          });
          setData(newData);
          console.log(newData, "new data");
        });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchPlans();
  }, []);

  const handleMenuClick = (record, e) => {
    if (e.key === "1") {
      setEditModalVisible(true);
      form.setFieldsValue({
        id: record.id,
        planname: record.planname,
        description: record.description,
        lutaccess: record.lutaccess,
        monthprice: record.monthprice,
        musicaccess: record.musicaccess,
        soundaccess: record.soundaccess,
        yearprice: record.yearprice,
      });
    } else if (e.key === "2") {
      confirm({
        title: `Are you sure delete this record?`,
        onOk() {
          db.collection("plans").doc(record.id).delete();
          fetchPlans();
          message.success("Successfully delete!");
        },
      });
    }
  };

  const handleAddProduct = async (value) => {
    setLoading(true);
    db.collection("plans")
      .doc(uuidv4())
      .set({
        planname: value.planname,
        description: value.description,
        lutaccess: value.lutaccess,
        monthprice: value.monthprice,
        musicaccess: value.musicaccess,
        soundaccess: value.soundaccess,
        yearprice: value.yearprice,
      })
      .then((doc) => {
        setIsModalVisible(false);
        message.success("plan created!");
        setLoading(false);
        fetchPlans();
      })
      .catch(function (error) {
        setIsModalVisible(false);
        message.error("plan not created!");
        setLoading(false);
        console.log(error);
      });
  };

  const handleEditProduct = async (value) => {
    setLoading(true);
    db.collection("plans")
      .doc(value.id)
      .update({
        planname: value.planname,
        description: value.description,
        lutaccess: value.lutaccess,
        monthprice: value.monthprice,
        musicaccess: value.musicaccess,
        soundaccess: value.soundaccess,
        yearprice: value.yearprice,
      })
      .then((doc) => {
        setEditModalVisible(false);
        message.success("plan updated!");
        setLoading(false);
        fetchPlans();
      })
      .catch(function (error) {
        setEditModalVisible(false);
        message.error("plan not updated");
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <div className="App">
      <ModalForm isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}>
        <Form name="control-ref" layout="horizontal" onFinish={handleAddProduct}>
          <Form.Item name="planname" rules={[{ required: true }]} label={`Plan Name`} hasFeedback {...formItemLayout}>
            <Input />
          </Form.Item>
          <Form.Item
            name="monthprice"
            rules={[{ required: true }]}
            label={`Month Price`}
            hasFeedback
            {...formItemLayout}
          >
            <InputNumber size="large" min={1} max={100000} />
          </Form.Item>
          <Form.Item name="yearprice" rules={[{ required: true }]} label={`Year Price`} hasFeedback {...formItemLayout}>
            <InputNumber size="large" min={1} max={100000} />
          </Form.Item>
          <Form.Item
            name="description"
            rules={[{ required: true }]}
            label={`Description`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>

          <Form.Item name="lutaccess" rules={[{ required: true }]} label={`lut Access`} hasFeedback {...formItemLayout}>
            <Select size="large" allowClear style={{ width: "100%" }} placeholder="Select an Option">
              <Option value="true">true</Option>
              <Option value="false">false</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="musicaccess"
            rules={[{ required: true }]}
            label={`Music Access`}
            hasFeedback
            {...formItemLayout}
          >
            <Select size="large" allowClear style={{ width: "100%" }} placeholder="Select an Option">
              <Option value="true">true</Option>
              <Option value="false">false</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="soundaccess"
            rules={[{ required: true }]}
            label={`Sound Access`}
            hasFeedback
            {...formItemLayout}
          >
            <Select size="large" allowClear style={{ width: "100%" }} placeholder="Select an Option">
              <Option value="true">true</Option>
              <Option value="false">false</Option>
            </Select>
          </Form.Item>
          {/* <Row justify="center">
            <Form.Item>
              <Upload beforeUpload={() => false} onChange={(e) => setUploadImg(e.file)}>
                <Button icon={<UploadOutlined />}>Click to Upload Image</Button>
              </Upload>
            </Form.Item>
          </Row> */}
          <Row justify="center">
            <Button type="primary" htmlType="submit" loading={loading} style={{ width: "80%" }}>
              Add Plan
            </Button>
          </Row>
        </Form>
      </ModalForm>

      <ModalForm isModalVisible={editModalVisible} setIsModalVisible={setEditModalVisible}>
        <Form name="edit-fomr" layout="horizontal" form={form} onFinish={handleEditProduct}>
          <Form.Item name="planname" rules={[{ required: true }]} label={`Plan Name`} hasFeedback {...formItemLayout}>
            <Input />
          </Form.Item>
          <Form.Item
            style={{ display: "none" }}
            name="id"
            rules={[{ required: true }]}
            label={`ID`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="monthprice"
            rules={[{ required: true }]}
            label={`Month Price`}
            hasFeedback
            {...formItemLayout}
          >
            <InputNumber size="large" min={1} max={100000} />
          </Form.Item>
          <Form.Item name="yearprice" rules={[{ required: true }]} label={`Year Price`} hasFeedback {...formItemLayout}>
            <InputNumber size="large" min={1} max={100000} />
          </Form.Item>
          <Form.Item
            name="description"
            rules={[{ required: true }]}
            label={`Description`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>

          <Form.Item name="lutaccess" rules={[{ required: true }]} label={`lut Access`} hasFeedback {...formItemLayout}>
            <Select size="large" allowClear style={{ width: "100%" }} placeholder="Select a country">
              <Option value="true">true</Option>
              <Option value="false">false</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="musicaccess"
            rules={[{ required: true }]}
            label={`Music Access`}
            hasFeedback
            {...formItemLayout}
          >
            <Select size="large" allowClear style={{ width: "100%" }} placeholder="Select a country">
              <Option value="true">true</Option>
              <Option value="false">false</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="soundaccess"
            rules={[{ required: true }]}
            label={`Sound Access`}
            hasFeedback
            {...formItemLayout}
          >
            <Select size="large" allowClear style={{ width: "100%" }} placeholder="Select a country">
              <Option value="true">true</Option>
              <Option value="false">false</Option>
            </Select>
          </Form.Item>
          <Row justify="center">
            <Button type="primary" htmlType="submit" loading={loading} style={{ width: "80%" }}>
              Edit Plan
            </Button>
          </Row>
        </Form>
      </ModalForm>
      <Form name="control-ref">
        <Row justify="space-between">
          <Col span={8}>
            <Form.Item name="name">
              <Search
                placeholder={`Search Name`}
                // onSearch={this.handleSubmit}
              />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Button type="ghost" onClick={() => setIsModalVisible(true)}>
              Create
            </Button>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Table
          // {...tableProps}
          pagination
          dataSource={data}
          // className={styles.table}
          bordered
          scroll={{ x: 800 }}
          columns={columns}
          simple
          rowKey={(record) => record.id}
        />
      </div>
    </div>
  );
};

export default Products;
